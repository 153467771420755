import React from "react";
import '../css/default.css';
import { getItem, setItem }   from '../Storage';

export default class Screen extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    let max_width      = 480;
    let window_width   = window.innerWidth;
    let window_height  = window.innerHeight;
    let screen_width   = window_width > max_width ? max_width : window_width;
    let screen_height  = window_height;
    let bg_color       = this.props.background_color || '#FFFFFF';
    let screen_class   = window_width > max_width ? 'box-shadow' : '';
        screen_class   = this.props.shadow === false   ? '' : screen_class;
    let gradient_class = window_width > max_width      ? '' : ' screen-gradient';
        gradient_class = this.props.gradient === false ? '' : gradient_class;

    let background_image_obj  = this.get_background_images();
    let background_image_url  = background_image_obj.background_image_url;
    let mobile_background_url = background_image_obj.mobile_background_image_url;
    let hide_background       = background_image_obj.hide_background_image;
    let narrow_bg_color       = this.props.shadow !== false && mobile_background_url ? '' : 'white';

    let partner             = getItem('partner');
    let is_embark           = partner && partner.name && partner.name === 'embark' ? true : false;
    let is_intro_sliders    = this.props.intro_slides ? true : false;

    if (!this.props.bg_image || hide_background === true) {
      background_image_url  = '';
      mobile_background_url = '';
    }

    if (is_embark && is_intro_sliders) {
      narrow_bg_color = '#F2F1EE';
    }

    // https://i.imgur.com/2eFsUkU.jpeg
    // https://i.imgur.com/7v6KBQj.png

    return (
      <div className='screen-container' style={{ backgroundImage: this.props.shadow === false ? '' : `url(${ background_image_url })`, backgroundSize: 'auto 100%', width: window_width, height: window_height, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: bg_color }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: window_width, height: screen_height, alignItems: 'center' }}>
          <div className={screen_class + gradient_class} style={{ backgroundColor: narrow_bg_color, backgroundImage: this.props.shadow !== false ? `url(${ mobile_background_url })` : '', backgroundSize: 'auto 100%', display: 'flex', flexDirection: 'column', width: screen_width, height: screen_height, ...this.props.style }}>
            { this.props.children }
          </div>
        </div>
      </div>
    );
  }

  get_background_images = () => {
    let partner = getItem('partner');

    let background_image_url  = partner && partner.background_image_url  ? partner.background_image_url  : '';
    let mobile_bg_image_url   = partner && partner.mobile_bg_image_url   ? partner.mobile_bg_image_url   : '';
    let hide_background_image = partner && partner.hide_background_image === true ? true : false;

    return { background_image_url: background_image_url, mobile_background_image_url: mobile_bg_image_url, hide_background_image: hide_background_image }
  }

}
