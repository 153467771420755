import React from 'react';
import UtilitiesController from './utilitiesController';
import AuthController from './authController';

export default class ConsultationController {

  static exceptionWrapper = (fn) => async (data) => {
      try {
          return await fn(data).catch((err) => {
              throw err;
          });
      } catch (err) {
          return {
              success: false,
              error: err.message
              }
      }
  };

  static createCareConsultation = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post('/v5/api/care/new/blank_consultation', data, true);
    return response
  });

  static editCareConsultation = ConsultationController.exceptionWrapper(async (data) => {
    let response  = await UtilitiesController.post('/v5/api/care/update/care_consultation', data, true);
    return response
  });

  static getCareConsultationDetailsFromCode = ConsultationController.exceptionWrapper(async (code) => {
      let con_code = code ? code.toLowerCase() : '';
      let response = await UtilitiesController.get(`/v3/api/care_client/client_care_consultation_details/${con_code}`, {}, true);
      return response
  });

  static getCareFormSessionData = ConsultationController.exceptionWrapper(async (code) => {
      let response = await UtilitiesController.get(`/v3/care_session_data/${code}`, {}, true);
      return response
  });

  static getCareConsultationDetails = ConsultationController.exceptionWrapper(async (care_consultation_id) => {
      let response          = await UtilitiesController.get(`/v3/api/care/care_consultation_details/${care_consultation_id}`, {}, true);
      let care_consultation = response && response.data && response.data.care_consultation ? response.data.care_consultation : {};

      if (response.success && care_consultation) {
        return care_consultation;
      }

      return response
  });

  static getIntakeQuestions = ConsultationController.exceptionWrapper(async (params) => {
      let practice_id = params.practice_id;
      let partner_id  = params.partner_id;
      let response    = await UtilitiesController.get(`/v3/api/care/practice_intake_questions/${practice_id}/${partner_id}`, {}, true);
      let questions   = response && response.data && response.data.questions ? response.data.questions : [];
      return questions
  });

  static createIntakeResponse = ConsultationController.exceptionWrapper(async (data) => {
      let response  = await UtilitiesController.post('/v4/api/create_intake_response', data, true);

      return response
  });

  static editIntakeResponse = ConsultationController.exceptionWrapper(async (data) => {
      let response  = await UtilitiesController.post('/v4/api/edit_intake_response', data, true);

      return response
  });

  static getIntakeResponse = ConsultationController.exceptionWrapper(async (intake_response_id) => {
      let response          = await UtilitiesController.get(`/v4/api/get_intake_response/${intake_response_id}`, {}, true);

      return response
  });

  static getOnlineChatProviders = ConsultationController.exceptionWrapper(async ({ practice_id, partner_id }) => {
    let request_body          = { practice_id: practice_id, partner_id: partner_id };
    let response              = await UtilitiesController.post(`/v5/api/care/get/online_chat_providers`, request_body, true);
    let online_providers_data = response && response.data ? response.data : {};

    if (response.success) {
      return online_providers_data;
    }

    return response
  });

  static getAvailableSlots = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v3/api/care_client/available_appointment_slots`, data, true);

    if (response && response.success && response.data && response.data.availability_slots) {
      return response.data.availability_slots;
    }

    return response
  });

  static getTrainingAvailableSlots = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v3/api/care_client/available_trainer_appointment_slots`, data, true);

    if (response && response.success && response.data && response.data.availability_slots) {
      return response.data.availability_slots;
    }

    return response
  });

  static cancelVideoConsultation = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v3/api/care_client/cancel_care_consultation`, data, true);
    return response
  });

  static assignVideoAppointment = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v4/api/assign_video_appointment`, data, true);
    return response
  });

  static assignLiveChat = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v4/api/assign_live_chat`, data, true);
    return response
  });

  static assignAsyncChat = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v4/api/assign_async_chat`, data, true);
    return response
  });

  static assignLiveVideo = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v4/api/assign_live_video`, data, true);
    return response
  });

  static getConsultationChatMessages = ConsultationController.exceptionWrapper(async (care_consultation_id) => {
    let response = await UtilitiesController.get(`/v3/api/care/chat_messages/${care_consultation_id}`, {}, true);
    return response
  });

  static sendCareConsultationMessage = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v3/api/care/chat_message`, data, true);
    return response
  });

  static getOnlineLiveVideoProviders = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.get(`/v4/api/live_video_online_providers/${data.practice_id}/${data.partner_id}`, {}, true);
    return response;
  });

  static setLatestLivePingTime = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v4/api/update_ping_time/${data.care_consultation_id}`, {}, true);
    return response;
  });

  static getCareConsultationDetails = ConsultationController.exceptionWrapper(async (care_consultation_id) => {
    let response          = await UtilitiesController.get(`/v3/api/care/care_consultation_details/${care_consultation_id}`, {}, true);
    let care_consultation = response && response.data && response.data.care_consultation ? response.data.care_consultation : {};

    if (response.success && care_consultation) {
      return care_consultation;
    }

    return response
  });

  static submitCareConsultationFeedback = ConsultationController.exceptionWrapper(async (data) => {
    let response = await UtilitiesController.post(`/v3/api/care_client/add_care_feedback`, data, true);

    return response
  });

  static getUpcomingVideoConsultations = ConsultationController.exceptionWrapper(async (partner_id) => {
    let response = await UtilitiesController.get(`/v4/api/video_upcoming_consultations/${partner_id}`, {}, true);
    return response;
  });

  static getClientChatConsultations = ConsultationController.exceptionWrapper(async (partner_id) => {
    let response = await UtilitiesController.get(`/v4/api/chat_consultations/${partner_id}`, {}, true);
    return response;
  });

}
