export const config = {
    VIDEO_BASE_URL: "https://video.teletails.com",

    // LOCAL
    // apiURL: "http://localhost:3000/data",
    // CHAT_URL: "http://localhost:8000",
    // NOTIF_URL: "http://localhost:4000",
    // PARTNER_CODE: 'hj5wlc',
    // DEFAULT_PARTNER_CODE: '187m3d',

    // DEVELOPMENT
    // apiURL: "https://dev-elb-api.teletails.com/data",
    // CHAT_URL:  "https://dev-elb-chat.teletails.com",
    // NOTIF_URL: "https://dev-elb-chat.teletails.com",
    // PARTNER_CODE: 'hj5wlc',
    // DEFAULT_PARTNER_CODE: '187m3d',

    // apiURL: "http://192.168.12.209:3000/data",
    // CHAT_URL: "http://192.168.12.209:8000",
    // NOTIF_URL: "http://192.168.12.209:4000",

    // apiURL:    'https://dev-elb-api.teletails.com/data',
    // CHAT_URL:  'https://dev-elb-chat.teletails.com',
    // NOTIF_URL: 'https://dev-elb-chat.teletails.com',

    // WAGMO
    apiURL:    'https://prod-elb-api.teletails.com/data',
    CHAT_URL:  'https://prod-elb-chat.teletails.com',
    NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    DEFAULT_PARTNER_CODE: 'tele18',
    PARTNER_CODE: 's9g33v',

    // USSA
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: '2kx82t',

    // GENIUSTAG
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'qwnge7',

    // DARWINS
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'f57rqg',

    // FABLE
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'vsqug5',

    // DOGSTOP
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'cjgquf',

    // CONCIERGE
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'nix1na',

    // CTV
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'jpgt8j',

    // UPC
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'v7stgt',

    // PRODUCTION HILLS
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'lmctec',

    // PRODUCTION ZESTY
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: '9bnfrj',

    // UPC TRAINING
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'h3zlgg',

    // DIGGS
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'lrm64u',

    // EMBARK
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'jpa4ab',

    // CRUMB
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'crumbs',

    // PRODUCTION WORLDCARE
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'qga8qi',

    // PETS52
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'rtghjm',

    // SUPPORT PETS
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'nxvxxf',

    // RUFFEOW
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'zf8tyt',

    // PRODUCTION TELETAILSPROD
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'l1w57r',

    // PRODUCTION GENERIC
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18'
};
