import React from "react";
import { DateUtils, StringUtils } from '../utils';
import { Button, Text, Input, Screen, Loading } from '../components';
import { Header, IntakeQuestion, SignIn, Chat, Feedback } from '../containers';
import { getItem, setItem }   from '../Storage';
import UserController         from '../controllers/userController';
import ConsultationController from '../controllers/consultationController';

export default class ChatScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      care_consultation: {},
      care_consultation_id: '',
      is_canceled: false,
      is_resolved: false,
      display_sign_in: false,
      display_no_access: false,
      loading_screen: false
    };
  }

  componentDidMount = async () => {
    this.setState({ loading_screen: true });

    let partner_code               = this.get_url_partner_code();
    let care_consultation_code     = this.get_url_care_consultation_code();
    let saved_care_consultation_id = getItem('care_consultation_id');

    if (partner_code && care_consultation_code && !saved_care_consultation_id) {
      this.setState({ partner_code: partner_code, care_consultation_code: care_consultation_code });
      this.pull_consultation_id_from_code(partner_code, care_consultation_code);
    }

    if (saved_care_consultation_id) {
      this.pull_latest_care_consultation_data();
    }

    await DateUtils.delay(2600)

    this.setState({ loading_screen: false });
  }

  render_chat_section = () => {
    let height            = this.state.window_height;
    let care_consultation = this.state.care_consultation;

    return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, flex: 1 }}>
      <Chat is_resolved={this.state.is_resolved}
            screen_height={height}
            navigate={this.props.navigate}
            care_consultation={care_consultation}
            care_consultation_update_action={ async () => {
              this.pull_latest_care_consultation_data();
            }} />
    </div>
  }

  render_no_access = () => {
    return (
      <Screen>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
          <Text style={{ fontWeight: 'bold', fontSize: 16 }}>You do not have access to this consultation.</Text>
        </div>
      </Screen>
    );
  }

  render_sign_in = () => {
    return (
      <Screen>
        <SignIn success_action={ () => { this.pull_consultation_id_from_code() }}/>
      </Screen>
    );
  }

  render_feedback = () => {

    return (
      <Screen>
        <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
          <Header title='Feedback' />
          <Feedback skip_feedback_action={ () => { this.setState({ hide_feedback: true }) }} />
        </div>
      </Screen>
    )
  }

  render_screen_loading = (screen_title) => {
    return <Screen>
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
        <div style={{ height: 140 }} />
        <Loading type='chat' title='Retrieving Chat Messages...' />
      </div>
    </Screen>
  }

  render() {
    if (this.state.loading_screen) {
      return this.render_screen_loading();
    }

    if (this.state.display_sign_in) {
      return this.render_sign_in();
    }

    if (this.state.display_no_access) {
      return this.render_no_access();
    }

    if (this.state.display_feedback && this.state.hide_feedback !== true) {
      return this.render_feedback();
    }

    return (
      <Screen style={{ overflow: 'hidden' }} >
        { this.render_chat_section() }
      </Screen>
    );
  }

  pull_latest_care_consultation_data = async () => {
    let partner               = getItem('partner');
    let signed_in_user_id     = getItem('user_id');
    let care_consultation_id  = getItem('care_consultation_id');
    let care_consultation_res = await ConsultationController.getCareConsultationDetails(care_consultation_id);

    let care_consultation     = care_consultation_res && care_consultation_res.care_consultation_details ? care_consultation_res.care_consultation_details : {};
    let is_canceled           = care_consultation && care_consultation.status && care_consultation.status === 'CANCELED' ? true : false;
    let is_resolved           = care_consultation && care_consultation.status && care_consultation.status === 'RESOLVED' ? true : false;

    let client_id             = care_consultation && care_consultation.client_id ? care_consultation.client_id : '';
    let display_no_access     = signed_in_user_id && client_id && client_id === signed_in_user_id ? false : true;
    let display_feedback      = care_consultation && care_consultation._id && !care_consultation.feedback_data && is_resolved ? true : false;
        display_feedback      = partner           && partner.feedback_enabled === false ? false : display_feedback;

    if (care_consultation) {
      let has_chat_start = care_consultation && (care_consultation.status === 'ACTIVE' || care_consultation.status === 'IN_PROGRESS');
      setItem('has_chat_start', has_chat_start);
    }

    this.setState({ care_consultation_id: care_consultation_id, care_consultation: care_consultation, is_canceled: is_canceled, is_resolved: is_resolved, display_sign_in: false, display_no_access: display_no_access, window_height: window.innerHeight, display_feedback: display_feedback });
  }

  pull_consultation_id_from_code = async (partner_code, care_consultation_code) => {
    let token         = getItem('token');
    let user_id       = getItem('user_id');
    let partner       = getItem('partner');
    let is_signed_in  = token && user_id ? true : false;
    let consult_code  = this.state.care_consultation_code || care_consultation_code;
        consult_code  = consult_code.toLowerCase();
    let saved_partner = partner && partner._id ? true : false;

    if (is_signed_in) {
      let code_consultation_res = await ConsultationController.getCareConsultationDetailsFromCode(consult_code);
      let care_consultation_id  = code_consultation_res && code_consultation_res.data && code_consultation_res.data.care_consultation && code_consultation_res.data.care_consultation._id ? code_consultation_res.data.care_consultation._id : '';
      setItem('care_consultation_id', care_consultation_id);
      this.pull_latest_care_consultation_data();
    }

    if (!is_signed_in && saved_partner) {
      this.setState({ display_sign_in: true, code: consult_code });
    }

    if (!is_signed_in && !saved_partner) {
      this.pull_partner_from_code(partner_code);
    }
  }

  pull_partner_from_code = async (partner_code) => {
    let partner = await UserController.getPartnerDetails({ code: partner_code });
    if (partner) {
      setItem('partner', partner);
      setItem('partner_name', partner.name);
      this.pull_consultation_id_from_code(partner_code);
    }
  }

  get_url_partner_code = () => {
    let current_url  = window.location.href;
    let split_up     = current_url.split('/');
    let codes_arr    = split_up && split_up.length > 0 ? split_up[split_up.length - 1].split('-') : '';
    let partner_code = codes_arr && codes_arr[0] ? codes_arr[0].toLowerCase() : '';
    let consult_code = codes_arr && codes_arr[1] ? codes_arr[1].toLowerCase() : '';

    return partner_code;
  }

  get_url_care_consultation_code = () => {
    let current_url  = window.location.href;
    let split_up     = current_url.split('/');
    let codes_arr    = split_up && split_up.length > 0 ? split_up[split_up.length - 1].split('-') : '';
    let partner_code = codes_arr && codes_arr[0] ? codes_arr[0] : '';
    let consult_code = codes_arr && codes_arr[1] ? codes_arr[1] : '';

    return consult_code;
  }

}
