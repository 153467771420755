import React from "react";
import { Header } from '../containers';
import { StringUtils, StyleUtils } from '../utils';
import { getItem, setItem }        from '../Storage';
import { Button, Text, Input, CheckBox, Error, Line } from '../components';
import '../css/default.css';

export default class CareSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      trouble_breathing: '',
      unresponsive: '',
      tos_checked: false,
      updated_default_category: false,
      display_no_credits: false
    };
  }

  componentDidMount = () => {

  }

  componentDidUpdate = () => {
    if (this.props.onCategoryChange && this.props.default_category && !this.state.updated_default_category) {
      this.props.onCategoryChange(this.props.default_category);
      this.setState({ updated_default_category: true });
    }
  }

  render_selection_buttons = () => {
    let is_live_chat  = this.props.consultation_type === 'LIVE_CHAT';
    let is_async_chat = this.props.consultation_type === 'ASYNC_CHAT';
    let is_video      = this.props.consultation_type === 'VIDEO';
    let is_live_video = this.props.consultation_type === 'LIVE_VIDEO';

    let display_live_chat  = this.props.online_providers_available === true ? true  : false;
    let display_async_chat = !display_live_chat;
    let display_live_video = this.props.live_video_available       === true ? true  : false;
    let display_video_apts = this.props.hide_video_option          === true ? false : true;
    let display_training   = this.props.training_enabled           === true;

    if (this.props.hide_chat_option) {
      display_live_chat  = false;
      display_async_chat = false;
    }

    if (this.props.hide_video_option) {
      display_live_video = false;
      display_video_apts = false;
    }

    if (this.props.hide_training_option) {
      display_training = false;
    }

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      { this.render_back_button() }
      { display_live_chat  ? this.render_care_type_button('LIVE_CHAT',  'Start a Veterinary Chat',  'fas fa-comment-alt', true,  false, () => { this.props.onTypeChange('LIVE_CHAT')  }) : null }
      { display_live_video ? this.render_care_type_button('LIVE_VIDEO', 'Live Video',               'fas fa-video',       true,  false, () => { this.props.onTypeChange('LIVE_VIDEO') }) : null }
      { display_async_chat ? this.render_care_type_button('ASYNC_CHAT', 'Start a Veterinary Chat',  'fas fa-comment-alt', false, false, () => { this.props.onTypeChange('ASYNC_CHAT') }) : null }
      { display_video_apts ? this.render_care_type_button('VIDEO',      'Veterinary Video Session', 'fas fa-video',       false, false, () => { this.props.onTypeChange('VIDEO')      }) : null }
      { display_training   ? this.render_care_type_button('TRAINING',   'Schedule Pet Training',    'fas fa-video',       false, this.props.airtable_access_enabled,  () => { this.props.onTypeChange('TRAINING')   }) : null }
    </div>
  }

  render_back_button = () => {
    if (!this.props.consultation_type) {
      return null;
    }

    return <div className='flex-container-row universal-button'
                style={{ backgroundColor: StyleUtils.get_button_color(), height: 35, alignSelf: 'flex-start', justifyContent: 'center', alignItems: 'center', borderRadius: 20, paddingRight: 20, paddingLeft: 20 }}
                onClick={ () => { this.props.onTypeChange('') }}>
      <span className='fas fa-arrow-left' style={{ color: StyleUtils.get_button_text_color(), fontSize: 12 }} />
      <span style={{ color: StyleUtils.get_button_text_color(), fontWeight: 'bold', fontSize: 12, marginLeft: 5 }}>Back</span>
    </div>

  }

  render_back_to_pets = () => {
    if (this.props.consultation_type || !this.props.display_change_pet) {
      return null;
    }

    return <div className='flex-container-column' style={{ marginTop: 30 }}>
      <Line />
      <Button style={{ width: 220, marginTop: 10, padding: 10, height: 45, alignSelf: 'center' }}
              titleStyle={{ fontSize: 14 }}
              grey={true}
              title='Select a different Pet'
              onClick={ () => {
                if (this.props.change_pet_action) {
                  this.props.change_pet_action();
                }
              }}/>
    </div>
  }

  render_category_dropdown = () => {
    if (this.props.default_category || !this.props.consultation_type) {
      return null;
    }

    let display_train  = this.props.consultation_type === 'TRAINING';
    let display_health = !display_train;

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 15 }}>
      <select className="universal-button block w-full mt-1 rounded-md bg-gray-100 focus:border-sky-300 focus:bg-white focus:ring-0"
              value={this.props.consultation_category}
              style={{ width: '100%', height: 48, borderRadius: 12, backgroundColor: 'white', borderWidth: 2, fontWeight: 'bold', fontSize: 14, color: '#1A1D1F', borderColor: '#EFEFEF' }}
              onChange={ (event) => {
                let selected_category = event.target.value;
                    selected_category = selected_category === 'select' ? '' : selected_category;
                this.props.onCategoryChange(selected_category);
              }} >

        <option value="select">Select Subject</option>

        { display_train  ? <option value="puppy_skills">Puppy Skills</option>        : null }
        { display_train  ? <option value="basic_skills">Basic Skills</option>        : null }
        { display_train  ? <option value="problem_solving">Problem Solving</option>  : null }

        { display_health ? <option value="allergies">Allergies</option> : null }
        { display_health ? <option value="anxiety">Anxiety</option>     : null }
        { display_health ? <option value="behavior">Behavior</option>   : null }
        { display_health ? <option value="dental">Dental</option>       : null }
        { display_health ? <option value="general_advice">General Advice</option> : null }
        { display_health ? <option value="joint_health">Joint Health</option>     : null }
        { display_health ? <option value="nutrition">Nutrition</option>           : null }
        { display_health ? <option value="sensitive_stomach">Sensitive Stomach</option> : null }
        { display_health ? <option value="skin_and_ears">Skin and Ears</option>         : null }
        { display_health ? <option value="wellness">Wellness</option>                     : null }
        
      </select>
    </div>
  }

  render_tos_checkbox = () => {
    let is_embark = this.props.partner && this.props.partner.name && this.props.partner.name === 'embark';
    let category_selected = this.props.consultation_category && this.props.consultation_category !== '';

    if (is_embark && category_selected) {
      return <div className='flex-container-row' style={{ marginTop: 20, alignItems: 'flex-start' }}>
        <CheckBox checked={this.state.tos_checked} onClick={() => { this.setState({ tos_checked: !this.state.tos_checked }) }} style={{ marginRight: 0 }} />
        <Text style={{ fontSize: 14, color: '#6F767E', fontWeight: 'bold' }}>I agree to the <a className="tos-link" style={{ color: '#1dc2ff', marginRight: 5 }} target="_blank" href={'https://www.teletails.com/terms-of-service-embark'}>Terms of Service</a> and <a className="tos-link" style={{ color: '#1dc2ff', marginRight: 5 }} target="_blank" href={'https://www.teletails.com/privacy-policy-embark-on-call'}>Privacy Policy</a></Text>
      </div>
    }
  }

  render_triage_questions = () => {
    if (this.props.consultation_type === 'TRAINING' || !this.props.consultation_type || (this.props.category_enabled && !this.props.consultation_category)) {
      return null;
    }

    return <div style={{ display: 'flex', flexDirection: 'column', marginTop: 17, width: '100%', borderWidth: 2, borderColor: '#EFEFEF', borderRadius: 12, padding: 15 }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text style={{ marginBottom: 15, fontSize: 14, color: '#6F767E', fontWeight: 'bold' }}>Is your pet having trouble breathing?</Text>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CheckBox label='Yes' checked={this.state.trouble_breathing === 'yes'} onClick={ () => { this.setState({ trouble_breathing: 'yes' }) }} style={{ marginRight: 20 }} />
        <CheckBox label='No'  checked={this.state.trouble_breathing === 'no' } onClick={ () => { this.setState({ trouble_breathing: 'no'  }) }} />
      </div>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
      <Text style={{ marginBottom: 15, fontSize: 14, color: '#6F767E', fontWeight: 'bold' }}>Is your pet unresponsive or unable to interact with you?</Text>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CheckBox label='Yes' checked={this.state.unresponsive === 'yes' } onClick={ () => { this.setState({ unresponsive: 'yes' }) }} style={{ marginRight: 20 }} />
        <CheckBox label='No'  checked={this.state.unresponsive === 'no'  } onClick={ () => { this.setState({ unresponsive: 'no'  }) }} />
      </div>
    </div>

    </div>
  }

  render_care_type_button = (type, title, icon_class, is_green, display_credits, click_action) => {
    let button_class   = is_green ? StyleUtils.get_class('button-live') + ' flex-container-row' : StyleUtils.get_class('button-default') + ' flex-container-row'
    let one_selected   = this.props.consultation_type !== '';
    let is_selected    = type === this.props.consultation_type;
    let icon_class_str = is_selected ? 'fas fa-check-circle' : icon_class;
    let is_training    = type === 'TRAINING';

    if (is_training) {
      button_class     = 'button-training flex-container-row';
    }

    if (one_selected && !is_selected) {
      return null;
    }

    return <div className={ button_class }
         style={{ alignItems: 'center', height: is_selected ? 48 : '', justifyContent: 'space-between', paddingLeft: 25, width: '100%', marginTop: 10, background: is_selected ? StyleUtils.get_button_color() : '', boxShadow: one_selected ? 'none' : '', borderRadius: is_selected ? 12 : 60 }}
         onClick={ () => {
           if (this.props.airtable_access_enabled && this.props.training_credits === 0 && type === 'TRAINING') {
             this.setState({ display_no_credits: true });
             return;
           }
           if (this.props.onTypeChange && is_selected) {
             this.props.onTypeChange('')
             this.setState({ display_no_credits: false });
             return;
           }
           if (this.props.onTypeChange) {
             this.props.onTypeChange(type)
             this.setState({ display_no_credits: false });
           }
         }}>
      <span className={ icon_class_str } style={{ fontSize: 16, marginRight: 10, color: StyleUtils.get_button_text_color() }}/>
      <Text style={{ color: StyleUtils.get_button_text_color(), fontWeight: '800', fontSize: is_selected ? 14 : 16 }}>{ title }</Text>
      <span style={{ width: 30 }} >
      { display_credits ? <div className='flex-container-column' style={{ backgroundColor: 'white', borderRadius: 20, alignItems: 'center' }}>
                            <Text style={{ color: '#4c4c4c', fontWeight: '800', fontSize: 14 }}>{ this.props.training_credits }</Text>
                          </div>  : null }
      </span>
    </div>
  }

  render_continue_button = () => {
    let skip_triage_questions = this.props.consultation_type === 'TRAINING' && this.props.consultation_type && this.props.consultation_category;
    
    let is_embark             = this.props.partner && this.props.partner.name && this.props.partner.name === 'embark';
    let tos_checked           = this.state.tos_checked;

    if (is_embark && (!tos_checked || !this.props.consultation_category)) {
      return null;
    }

    if (skip_triage_questions || (this.state.trouble_breathing === 'no' && this.state.unresponsive === 'no' && this.props.consultation_type)) {
      return <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
        <Error error={ this.props.error } style={{ marginTop: 10, marginBottom: 10 }} />
        <Button style={{ width: 300, marginTop: 10, height: 55, alignSelf: 'center' }}
                titleStyle={{ fontSize: 16 }}
                title='Continue'
                loading={this.props.loading_continue_button}
                onClick={ () => {
                  this.props.continue_action();
                }}/>
      </div>
    }

    if (this.state.trouble_breathing === 'yes' || this.state.unresponsive === 'yes') {
      return <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
        <Text style={{ textAlign: 'center', color: 'red' }}>This sounds like an emergency. Please call your nearest animal hospital.</Text>
      </div>
    }
  }

  render_no_credits_message = () => {
    if (!this.state.display_no_credits) {
      return null;
    }

    return <div className='flex-container-column' style={{ alignItems: 'center', paddingTop: 20 }}>
      <Text style={{ fontWeight: 'bold', fontSize: 15, width: '85%', textAlign: 'center', color: 'grey' }}>Looks like you are out of credits. <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginRight: 5 }} target="_blank" href={ this.props.training_access_url }>Add credits</a></Text>
      <Text style={{ fontWeight: 'bold', fontSize: 15, width: '85%', textAlign: 'center', color: 'grey' }}>or contact <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginRight: 5 }} target="_blank" href="mailto:support@teletails.com">support@teletails.com</a></Text>
    </div>
  }

  render() {
    let user       = getItem('user');
    let is_embark  = this.props.partner && this.props.partner.name && this.props.partner.name === 'embark';
    let first_name = user && user.first_name ? StringUtils.sentenceCase(user.first_name) + ',' : '';
    let title      = 'Welcome ' + first_name;
    let subtitle   = 'Select your preferred method of care';

    if (is_embark) {
      title    = '';
      subtitle = '';
    }

    return <div style={{ display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center' }}>
      <Header title={ title } subtitle={ subtitle } />
      { this.render_selection_buttons() }
      { this.render_no_credits_message()}
      { this.render_back_to_pets()      }
      { this.render_category_dropdown() }
      { this.render_triage_questions()  }
      { this.render_tos_checkbox()      }
      { this.render_continue_button()   }
    </div>
  }
}
