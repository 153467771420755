import React from "react";
import '../css/default.css';
import { StyleUtils } from '../utils';
import { getItem }    from '../Storage';
import { Text, Input } from '../components';

export default class IntakeQuestion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      response_object: {},
    };
  }

  componentDidMount() {

  }

  render_base_question = () => {
    let question_text = this.props.question && this.props.question.question_text ? this.props.question.question_text : '';

    return <div className='flex-container-column'>
      <Text style={{ fontSize: 14, marginBottom: 10, fontWeight: 'bold', color: '#6F767E' }}>{ question_text }</Text>
    </div>
  }

  render_choices = (choices) => {
    let num_of_choices = choices ? choices.length : 0;

    if (num_of_choices === 0) {
      return null;
    }

    let partner        = getItem('partner');
    let is_embark      = partner && partner.name && partner.name === 'embark' ? true : false;
    let text_color     = '#141415';
    let selected_color = is_embark ? '#141415' : 'white';

    let choice_rows = choices.map((choice_str) => {
      let selected_choice = this.state.response_object && this.state.response_object.choice_response ? this.state.response_object.choice_response : '';
      let selected_style  = { borderStyle: 'solid', borderWidth: 1, padding: 14, marginBottom: 3, alignItems: 'center', borderRadius: 12, borderColor: 'white' };
      let default_style   = { borderStyle: 'solid', borderWidth: 1, padding: 14, marginBottom: 3, alignItems: 'center', borderRadius: 12, background: 'white' };
      let is_selected     = selected_choice === choice_str;
      let choice_style    = is_selected ? selected_style : default_style;
      return <div key={choice_str}
                  className={ 'flex-container-column universal-button ' + StyleUtils.get_class('gradient-background') }
                  style={choice_style}
                  onClick={ () => {
                    let updated_res_obj = Object.assign({}, this.state.response_object);
                    updated_res_obj['choice_response'] = choice_str;
                    this.update_parent_object(updated_res_obj);
                    this.setState({ response_object: updated_res_obj });
                  }}>
        <Text style={{ color: is_selected ? selected_color : text_color, fontWeight: 'bold', fontSize: 14 }}>{ choice_str }</Text>
      </div>
    })

    return <div className='flex-container-column'>
      { choice_rows }
    </div>
  }

  render_text_prompt = (text_prompt) => {
    if (!text_prompt) {
      return null;
    }

    return <div className='flex-container-column' style={{ marginTop: 15 }}>
      <Text style={{ fontSize: 14, marginBottom: 10, fontWeight: 'bold', color: '#6F767E' }}>{ text_prompt }</Text>
    </div>
  }

  render_input_box = () => {
    let question      = this.props.question;
    let choices       = question && question.choices     ? question.choices     : [];
    let text_prompt   = question && question.text_prompt ? question.text_prompt : '';
    let display_input = choices.length === 0 || text_prompt;
    let response_text = this.state.response_object && this.state.response_object.text_response ? this.state.response_object.text_response : '';

    if (!display_input) {
      return null;
    }

    return <div className='flex-container-column'>
      <Input value={ response_text }
             style={{ marginBottom: 10, fontSize: 16, height: 50, paddingTop: 10, paddingBottom: 10 }}
             onChange={(input_text)=>{
               let new_response_text = input_text;
               let updated_res_obj   = Object.assign({}, this.state.response_object);
               updated_res_obj['text_response'] = new_response_text;
               this.update_parent_object(updated_res_obj)
               this.setState({ response_object: updated_res_obj });
             }}/>
    </div>
  }

  render_question = () => {
    let question    = this.props.question;
    let choices     = question && question.choices     ? question.choices     : [];
    let text_prompt = question && question.text_prompt ? question.text_prompt : '';

    return <div className='flex-container-column intake-container' style={{ marginTop: 20 }}>
      { this.render_base_question()          }
      { this.render_choices(choices)         }
      { this.render_text_prompt(text_prompt) }
      { this.render_input_box(question)      }
    </div>
  }

  render() {
    return (
      <div style={{  }}>
        { this.render_question() }
      </div>
    );
  }

  update_parent_object = (lastest_response_object) => {
    let question_text = this.props.question && this.props.question.question_text ? this.props.question.question_text : '';
    let updated_obj   = Object.assign({}, lastest_response_object);

    if (!updated_obj.question_text) {
      updated_obj['question_text'] = question_text;
    }

    if (this.props.on_change) {
      this.props.on_change(updated_obj);
    }
  }

}
