import React from 'react';
import UtilitiesController from './utilitiesController';
import AuthController from './authController';

export default class UserController {

    static exceptionWrapper = (fn) => async (data) => {
        try {
            return await fn(data).catch((err) => {
                throw err;
            });
        } catch (err) {
            return {
                success: false,
                error: err.app_error ? err.app_error : err.message
            }
        }
    };

    static updateProfile = UserController.exceptionWrapper(async (data) => {
        let response = await UtilitiesController.post(`/v4/api/user/update_profile`, data, true);
        return response;
    });

    static getPartnerDetails = UserController.exceptionWrapper(async (data) => {
      let code     = data.code ? data.code.toLowerCase() : '';
      let response = await UtilitiesController.post(`/v5/get/care_partner`, data, true);

      let partner  = response && response.success && response.data && response.data.partner ? response.data.partner : {};
      return partner;
    });

    static sendRegisterPhoneCode = UserController.exceptionWrapper(async (data) => {
      let phone_code_data = {
        ...data,
        // partner_name: 'DodoVet'
      }

      return await UtilitiesController.post('/v4/send_phone_code', phone_code_data, true);
    });

    static validatePhoneAndUpdate = UserController.exceptionWrapper(async (data) => {
      let response = await UtilitiesController.post(`/v4/api/user/validate_phone_number`, data, true);

      return response;
    });

    static getAirtableAccess = UserController.exceptionWrapper(async (data) => {
      let response = await UtilitiesController.post(`/v5/api/care/get/airtable_access`, data, true);
      return response;
    });

    static getPartnerArticles = UserController.exceptionWrapper(async (table_key) => {
      let req_data = { table_name: table_key };
      let response = await UtilitiesController.post(`/v5/api/care/get/partner_articles`, req_data, true);
      return response
    });

}
