import '../css/default.css';
import '../css/hills.css';

import { getItem, setItem } from '../Storage';

const get_class = (class_name) => {
  let partner        = getItem('partner');
  let partner_name   = partner && partner.name ? partner.name : '';
  let prefix_string  = partner_name;
  let combined_class = class_name;

  switch (partner_name) {
    case 'teletails':
      combined_class = 'teletails-' +  class_name;
      break;
    case 'hills':
      combined_class = 'hills-' +  class_name;
      break;
    case 'amazon':
      combined_class = 'amazon-' +  class_name;
      break;
    case 'drmarty':
      combined_class = 'drmarty-' +  class_name;
      break;
    case 'snugglepuppy':
      combined_class = 'snugglepuppy-' +  class_name;
      break;
    case 'darwins':
      combined_class = 'darwins-' +  class_name;
      break;
    case 'getjoy':
      combined_class = 'getjoy-' +  class_name;
      break;
    case 'wag':
      combined_class = 'wag-' +  class_name;
      break;
    case 'wagmo':
      combined_class = 'wagmo-' +  class_name;
      break;
    case 'ussa':
      combined_class = 'ussa-' +  class_name;
      break;
    case 'dogac':
      combined_class = 'hills-' +  class_name;
      break;
    case 'geniustag':
      combined_class = 'geniustag-' +  class_name;
      break;
    case 'conciergevet':
      combined_class = 'conciergevet-' +  class_name;
      break;
    case 'fable':
      combined_class = 'fable-' +  class_name;
      break;
    case 'zestypaws':
      combined_class = 'zestypaws-' +  class_name;
      break;
    case 'pupford':
      combined_class = 'pupford-' +  class_name;
      break;
    case 'upc':
      combined_class = 'upc-' +  class_name;
      break;
    case 'embark':
      combined_class = 'embark-' +  class_name;
      break;
    default:

  }

  return combined_class;
}

const get_color = () => {
  let partner        = getItem('partner');
  let partner_name   = partner && partner.name ? partner.name : '';
  let color_hex      = '#1dc2ff';

  switch (partner_name) {
    case 'teletails':
      color_hex = '#1dc2ff';
      break;
    case 'amazon':
      color_hex = '#FF9900';
      break;
    case 'hills':
      color_hex = '#0054A8';
      break;
    case 'drmarty':
      color_hex = '#18A574';
      break;
    case 'snugglepuppy':
      color_hex = '#00A2D5';
      break;
    case 'darwins':
      color_hex = '#00214A';
      break;
    case 'getjoy':
      color_hex = '#77cc35';
      break;
    case 'wag':
      color_hex = '#00BF8F';
      break;
    case 'wagmo':
      color_hex = '#17493D';
      break;
    case 'ussa':
      color_hex = '#d32110';
      break;
    case 'dogac':
      color_hex = '#0054A8';
      break;
    case 'geniustag':
      color_hex = '#F82D3F';
      break;
    case 'conciergevet':
      color_hex = '#EFB256';
      break;
    case 'fable':
      color_hex = '#738DE8';
      break;
    case 'zestypaws':
      color_hex = '#ec7700';
      break;
    case 'pupford':
      color_hex = '#e64b38';
      break;
    case 'upc':
      color_hex = '#3b6cf6';
      break;
    case 'embark':
      color_hex = '#ffce34';
      break;
    default:

  }

  return color_hex;
}

const get_button_color = () => {
  let partner = getItem('partner');
  let style_customizations = partner && partner.style_customizations ? partner.style_customizations : {};
  let button_color = style_customizations.button_color_hex ? style_customizations.button_color_hex : '#1dc2ff';
  return button_color;
}

const get_button_text_color = () => {
  let partner = getItem('partner');
  let style_customizations = partner && partner.style_customizations ? partner.style_customizations : {};
  let button_text_color = style_customizations.button_text_color_hex ? style_customizations.button_text_color_hex : '#ffffff';
  return button_text_color;
}

const get_header_color = () => {
  let partner = getItem('partner');
  let style_customizations = partner && partner.style_customizations ? partner.style_customizations : {};
  let header_color = style_customizations.header_color_hex ? style_customizations.header_color_hex : '#1dc2ff';
  return header_color;
}

const get_primary_color = () => {
  let partner = getItem('partner');
  let style_customizations = partner && partner.style_customizations ? partner.style_customizations : {};
  let primary_color = style_customizations.primary_color_hex ? style_customizations.primary_color_hex : '#1dc2ff';
  return primary_color;
}

export default {
  get_class,
  get_color,
  get_button_color,
  get_button_text_color,
  get_header_color,
  get_primary_color,
}
