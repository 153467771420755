import React from "react";
import '../css/default.css';
import { getItem }    from '../Storage';
import { StyleUtils } from '../utils';

export default class CheckBox extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    if (this.props.hide) {
      return null;
    }

    let label        = this.props.label || '';
    let is_checked   = this.props.checked || false;
    let click_action = this.props.onClick;
    let checked_clr  = this.props.color || StyleUtils.get_primary_color();
    let label_style  = this.props.label_style || {};
    let icon_color   = 'white';

    let partner   = getItem('partner');
    let is_embark = partner && partner.name && partner.name === 'embark' ? true : false;

    if (is_embark) {
      icon_color = 'black';
    }

    return <div className='flex-container-row universal-button' style={{ alignItems: 'center', ...this.props.style }} onClick={ () => { click_action() }}>
      { is_checked ? <div className={ 'flex-container-column ' + StyleUtils.get_class('gradient-background') } style={{ justifyContent: 'center', alignItems: 'center', height: 24, width: 24, backgroundColor: checked_clr, borderRadius: 6, marginRight: 10 }}>
        <span className='fas fa-check' style={{ color: icon_color, fontSize: 10 }} />
      </div> : null }
      { !is_checked ? <div className='flex-container-column' style={{ justifyContent: 'center', alignItems: 'center', height: 24, width: 24, backgroundColor: 'white', borderRadius: 6, marginRight: 10, borderWidth: 1, borderStyle: 'solid', borderColor: '#DFE3E4' }}>

      </div> : null }
      <div style={{ fontSize: 14, fontWeight: 'bold', color: '#141415', ...label_style }}>{ label }</div>
    </div>
  }
}
