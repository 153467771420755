import React from "react";
import { Button, Text, Input, CheckBox } from '../components';
import { StyleUtils }          from '../utils';
import { getItem, setItem }    from '../Storage';
import { Header }              from '../containers';
import UserController          from '../controllers/userController';
import AuthController          from '../controllers/authController';

import '../css/default.css';

export default class NameZipcode extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      zipcode: '',
      agreed: false,
      loading_update: false
    };
  }

  componentDidMount() {

  }

  render_name_inputs = () => {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Header title='Profile' subtitle='Enter Your Details' />
      <Input type='text'
             placeholder='First Name'
             value={this.state.first_name}
             style={{ width: '100%', borderRadius: 12, height: 55 }}
             container_style={{  }}
             onChange={ (text_input) => {
               this.setState({ first_name: text_input })
             }} />
      <Input type='text'
             placeholder='Last Name'
             value={this.state.last_name}
             style={{ width: '100%', borderRadius: 12, height: 55 }}
             container_style={{ marginTop: 20 }}
             onChange={ (text_input) => {
               this.setState({ last_name: text_input })
             }} />
    </div>
  }

  render_zipcode_input = () => {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Input type='tel'
             placeholder='Zipcode'
             value={this.state.zipcode}
             style={{ width: '100%', borderRadius: 12, height: 55 }}
             container_style={{ marginTop: 20 }}
             onChange={ (text_input) => {
               this.setState({ zipcode: text_input })
             }} />
    </div>
  }

   render_tos_section = () => {
     let partner  = getItem('partner_name');
     let is_hills = partner && partner === 'hills' ? true : false;
     let tos_url  = 'https://www.teletails.com/tos';
         tos_url  = is_hills ? 'https://www.teletails.com/toshills' : tos_url;

     return <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, width: 330, alignSelf: 'center', padding: 10 }}>
      <CheckBox checked={ this.state.agreed } onClick={ () => { this.setState({ agreed: !this.state.agreed }) }} />
      <Text style={{ fontSize: 14, marginLeft: 5, color: '#6F767E', fontWeight: 'bold' }}>
        By continuing, you are agreeing to our
        <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginRight: 5 }} target="_blank" href={ tos_url }> Terms & Conditions</a>
        and
        <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginLeft: 2 }} target="_blank" href="https://www.teletails.com/privacypolicy"> Privacy Policy</a>.
      </Text>
     </div>
   }

  render_continue_button = () => {
    let continue_disabled = !this.state.agreed || !this.state.first_name || !this.state.last_name || !this.state.zipcode;
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button title='Continue'
              style={{ height: 55, marginTop: 15, marginRight: 10, marginLeft: 10, width: 300, alignSelf: 'center'  }}
              titleStyle={{ fontSize: 16 }}
              disabled={continue_disabled}
              loading={this.state.loading_update}
              onClick={ async () => {
                this.setState({ loading_update: true });

                let update_object = {
                  first_name: this.state.first_name,
                  last_name:this.state.last_name,
                  zipcode: this.state.zipcode
                };

                if (!this.state.first_name || !this.state.last_name) {
                  return;
                }

                let update_response = await UserController.updateProfile(update_object);
                let user_details    = await AuthController.getLoggedInUser();

                this.setState({ loading_update: false });

                if (this.props.success_action) {
                  this.props.success_action();
                }
              }}/>
    </div>
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center' }}>
        { this.render_name_inputs()     }
        { this.render_zipcode_input()   }
        { this.render_tos_section()     }
        { this.render_continue_button() }
      </div>
    );
  }
}
