import React from "react";
import '../css/default.css';
import { StringUtils, NavigationUtils, DateUtils, StyleUtils } from '../utils';
import { Button, Screen, Text, Spinner, Line, Input, Error, CheckBox, Loading } from '../components';
import { Header }             from '../containers';
import { Player }             from '@lottiefiles/react-lottie-player';
import { getItem, setItem }   from '../Storage';
import { Routes }             from '../navigation';
import AuthController         from '../controllers/authController';
import UtilitiesController    from '../controllers/utilitiesController';
import PetsController         from '../controllers/petsController';
import ConsultationController from '../controllers/consultationController';

export default class PetsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pets: [],
      name: '',
      type: '',
      type_other: '',
      weight: '',
      gender: '',
      birthday: '',
      spayed_neutered: false,
      selected_pet_id: '',
      input_section_title: 'Add New Pet',
      display_pet_inputs: false,
      display_edit_buttons: false,
      loading_save_pet: false,
      loading_screen: false
    };
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  componentDidMount = async () => {
    this.setState({ loading_screen: true });
    let care_consultation = getItem('care_consultation');
    let pets              = await PetsController.getAllPets();

    await DateUtils.delay(2200)

    this.setState({ pets: pets, loading_screen: false });
  }

  render_pet_inputs = () => {
    if (!this.state.display_pet_inputs) {
      return null;
    }

    let title     = this.state.input_section_title;
    let is_other  = this.state.type   === 'other';
    let is_male   = this.state.gender === 'MALE';
    let is_female = this.state.gender === 'FEMALE';

    let is_dog    = this.state.type === 'dog';
    let is_cat    = this.state.type === 'cat';

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Input type='text'
             label="Your Pet's Name"
             value={this.state.name}
             style={{ height: 50, fontSize: 16 }}
             container_style={{ marginTop: 10 }}
             onChange={ (text_input) => {
               this.setState({ name: text_input })
             }} />

      <div className='flex-container-column' style={{ marginTop: 20 }}>
        <Text style={{ fontSize: 14, fontWeight: 'bold', paddingLeft: 4, marginBottom: 8, color: '#6F767E' }}>{ 'Pet Type' }</Text>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={ 'universal-button ' + StyleUtils.get_class('gradient-background') }
               style={{ overflow: 'hidden', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', borderWidth: 2, borderStyle: 'solid', borderRadius: 12, borderColor: is_dog ? 'white' : '#EFEFEF', alignItems: 'center', flex: 1, height: 110, paddingBottom: 10, paddingTop: 10, background: is_dog ? '' : 'white'  }}
               onClick={ () => {
                 this.setState({ type: 'dog' });
               }}>
            <Player autoplay loop src={ require('../animations/dog-walking.json') } style={{ height: '95px', width: '95px', marginTop: -15, marginBottom: -15  }} />
            <div style={{  }}>
              <Text style={{ fontSize: 14, fontWeight: 'bold', color: is_dog ? 'white' : '#1C2123' }}>Dog</Text>
            </div>
          </div>
          <div className={ 'universal-button ' + StyleUtils.get_class('gradient-background') }
               style={{ overflow: 'hidden', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', borderWidth: 2, borderStyle: 'solid', borderRadius: 12, borderColor: is_cat ? 'white' : '#EFEFEF', alignItems: 'center', flex: 1, marginRight: 6, marginLeft: 6, height: 110, paddingBottom: 10, paddingTop: 10, background: is_cat ? '' : 'white' }}
               onClick={ () => {
                 this.setState({ type: 'cat' });
               }}>
            <Player autoplay loop src={ require('../animations/cat-tail-wag.json') } style={{ height: '120px', width: '120px', marginTop: -30, marginBottom: -25 }} />
            <div style={{ }}>
              <Text style={{ fontSize: 14, fontWeight: 'bold', color: is_cat ? 'white' : '#1C2123' }}>Cat</Text>
            </div>
          </div>
          <div className={ 'universal-button ' + StyleUtils.get_class('gradient-background') }
               style={{ overflow: 'hidden', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', borderWidth: 2, borderStyle: 'solid', borderRadius: 12, borderColor: is_other ? 'white' : '#EFEFEF', alignItems: 'center', flex: 1, marginRight: 6, marginLeft: 6, height: 110, paddingBottom: 10, paddingTop: 10, background: is_other ? '' : 'white' }}
               onClick={ () => {
                 this.setState({ type: 'other' });
               }}>
             <Player autoplay loop src={ require('../animations/cow-eating-grass.json') } style={{ height: '80px', width: '80px', marginTop: -8, marginBottom: -5 }} />
             <div style={{ }}>
               <Text style={{ fontSize: 14, fontWeight: 'bold', color: is_other ? 'white' : '#1C2123' }}>Other</Text>
             </div>
          </div>
        </div>
        { is_other ? <Input type='text'
                            label='Pet Type (Other)'
                            value={this.state.type_other}
                            style={{ height: 50, fontSize: 16 }}
                            container_style={{ marginTop: 10 }}
                            onChange={ (text_input) => {
                              this.setState({ type_other: text_input })
                            }} />
                   : null }
      </div>

      <div className='flex-container-column' style={{ marginTop: 20 }}>
        <Text style={{ fontSize: 14, fontWeight: 'bold', paddingLeft: 4, marginBottom: 8, color: '#6F767E' }}>{ 'Pet Gender' }</Text>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={ 'universal-button ' + StyleUtils.get_class('gradient-background') }
               style={{ display: 'flex', flexDirection: 'column', borderWidth: 2, borderStyle: 'solid', borderRadius: 12, borderColor: is_male ? 'white' : '#EFEFEF', alignItems: 'center', justifyContent: 'center', width: 120, height: 48, background: is_male ? '' : 'white' }}
               onClick={ () => {
                 this.setState({ gender: 'MALE' })
               }}>
            <Text style={{ fontSize: 14, fontWeight: 'bold', color: is_male ? 'white' : '#1A1D1F' }}>Male</Text>
          </div>
          <div className={ 'universal-button ' + StyleUtils.get_class('gradient-background') }
               style={{ display: 'flex', flexDirection: 'column', borderWidth: 2, borderStyle: 'solid', borderRadius: 12, borderColor: is_female ? 'white' : '#EFEFEF', alignItems: 'center', justifyContent: 'center', width: 120, marginRight: 6, marginLeft: 6, background: is_female ? '' : 'white' }}
               onClick={ () => {
                 this.setState({ gender: 'FEMALE' })
               }}>
            <Text style={{ fontSize: 14, fontWeight: 'bold', color: is_female ? 'white' : '#1A1D1F' }}>Female</Text>
          </div>
        </div>
      </div>

      <div className='flex-container-column' style={{ marginTop: 20 }}>
        <Text style={{ fontSize: 14, fontWeight: 'bold', paddingLeft: 4, marginBottom: 10, color: '#6F767E' }}>{ 'Is your pet spayed or neutered?' }</Text>
        <div style={{ display: 'flex', flexDirection: 'row' }}>

          <CheckBox label='Yes' checked={this.state.spayed_neutered}  onClick={ () => { this.setState({ spayed_neutered: true  }) }} style={{ marginRight: 20 }} />
          <CheckBox label='No'  checked={!this.state.spayed_neutered} onClick={ () => { this.setState({ spayed_neutered: false }) }} style={{ marginRight: 20 }} />

        </div>
      </div>

      <Input type='date'
             label="Your pet's birthday"
             value={this.state.birthday}
             style={{ borderWidth: 2, height: 50, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#1C2123', fontWeight: 'bold' }}
             container_style={{ marginTop: 20 }}
             onChange={ (text_input) => {
               this.setState({ birthday: text_input })
             }} />

      <Input type='tel'
             label="Approximate weight (lbs)"
             value={this.state.weight}
             style={{ height: 50, fontSize: 16 }}
             container_style={{ marginTop: 15 }}
             onChange={ (text_input) => {
               this.setState({ weight: text_input })
             }} />

        <Error error={this.state.error} style={{ marginTop: 15 }}/>

       <div className='flex-container-row' style={{ marginTop: 20, marginBottom: 40, justifyContent: 'center' }}>
        <Button title='Cancel'
                style={{ marginRight: 5, width: 150, height: 55 }}
                titleStyle={{ fontSize: 16 }}
                grey={true}
                onClick={ () => {
                  this.setState({
                    name: '',
                    type: '',
                    error: '',
                    updated_error: '',
                    weight: '',
                    gender: '',
                    selected_pet_id: '',
                    spayed_neutered: false,
                    input_section_title: 'Add New Pet',
                    display_pet_inputs: false,
                    loading_save_pet: false,
                    display_edit_buttons: false
                  })
                }}/>
        <Button title='Save Pet'
                loading={this.state.loading_save_pet}
                style={{ width: 150, borderWidth: 0, height: 55, boxShadow: 'none' }}
                titleStyle={{ fontSize: 16 }}
                onClick={ async () => {
                  this.setState({ loading_save_pet: true, error: '' });

                  let pet_weight     = this.state.weight && parseInt(this.state.weight) ? parseInt(this.state.weight) : 0;
                  let bday_date      = this.state.birthday || '';
                  let split_bday     = bday_date.split('-');
                  let formatted_bday = split_bday && split_bday.length === 3 ? split_bday[1] + '/' + split_bday[2] + '/' + split_bday[0] : '';

                  let pet_details = {
                    name: this.state.name,
                    birthday: formatted_bday,
                    gender: this.state.gender,
                    spayed: this.state.spayed_neutered,
                    neutered: this.state.spayed_neutered,
                    weight: pet_weight
                  }

                  if (this.state.type === 'other') {
                    pet_details['type'] = this.state.type_other;
                  } else {
                    pet_details['type'] = this.state.type;
                  }

                  if (this.state.display_edit_buttons && this.state.selected_pet_id) {
                    pet_details['patient_id'] = this.state.selected_pet_id;
                    let edit_pet_response     = await PetsController.updatePet(pet_details);
                    let edit_success          = edit_pet_response && edit_pet_response.success;
                    let pets_array            = await PetsController.getAllPets();
                    let error_message         = edit_success ? '' : 'Input validation error';
                    this.setState({ loading_save_pet: false, pets: pets_array, display_pet_inputs: !edit_success, display_edit_buttons: !edit_success, error: error_message, selected_pet_id: '' });
                  } else {
                    let add_pet_response      = await PetsController.addPet(pet_details);
                    let add_success           = add_pet_response && add_pet_response.success;
                    let pets_array            = await PetsController.getAllPets();
                    let error_message         = add_success ? '' : 'Input validation error';
                    this.setState({ loading_save_pet: false, pets: pets_array, display_pet_inputs: !add_success, display_edit_buttons: !add_success, error: error_message, selected_pet_id: ''  });
                  }
                }}/>
       </div>
    </div>
  }

  render_pets_list = () => {
    if (this.state.display_pet_inputs) {
      return null;
    }

    let partner     = getItem('partner')
    let allow_add   = partner && partner.allow_add_pet === true ? true : false;
    let display_add = !this.state.selected_pet_id && !this.state.display_edit_buttons && allow_add;

    let pets_arr    = this.state.pets || [];
    let pets_rows   = pets_arr.map((pet) => {
      let is_loading = this.state.loading_pet_id === pet._id;
      let is_dog     = pet && pet.type === 'dog';
      let is_cat     = pet && pet.type === 'cat';
      let is_other   = pet && !is_dog && !is_cat;
      let is_selected= this.state.selected_pet_id === pet._id;
      return <div className='universal-button'
                  key={pet._id}
                  style={{ display: 'flex', flexDirection: 'column', borderWidth: 2, borderColor: '#e7e7e7', borderStyle: 'solid', padding: 15, borderRadius: 12, marginBottom: 5 }}
                  onClick={ async () => {
                    if (this.state.loading_pet_id) {
                      return;
                    }
                    if (this.state.display_edit_buttons) {
                      let formatted_birthday = pet.birthday || '';
                      let split_bday         = formatted_birthday.split('/');
                      let input_date_format  = split_bday && split_bday.length === 3 ? split_bday[2] + '-' + split_bday[0] + '-' + split_bday[1] : '';
                      this.setState({
                        name: StringUtils.displayName(pet),
                        type: pet.type ? pet.type.toLowerCase() : '',
                        weight: pet.weight,
                        gender: pet.gender,
                        birthday: input_date_format,
                        spayed_neutered: pet.spayed,
                        selected_pet_id: pet._id,
                        input_section_title: 'Edit ' + StringUtils.displayName(pet) + "'s Profile",
                        display_pet_inputs: true,
                        loading_save_pet: false
                      })
                    } else {
                      this.setState({ selected_pet_id: is_selected ? '' : pet._id });
                    }
                  }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: 40, height: 40, marginRight: 15, overflow: 'hidden' }}>
              { is_dog   ? <Player autoplay loop src={ require('../animations/dog-walking.json') }      style={{ height: '60px', width: '60px', marginTop: -10, marginLeft: -10  }} /> : null }
              { is_cat   ? <Player autoplay loop src={ require('../animations/cat-tail-wag.json') }     style={{ height: '90px', width: '90px', marginTop: -30, marginLeft: -30  }} /> : null }
              { is_other ? <Player autoplay loop src={ require('../animations/cow-eating-grass.json') } style={{ height: '70px', width: '70px', marginTop: -8, marginBottom: -5 }} />  : null }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ fontSize: 12, fontWeight: 'bold', color: '#6F767E' }}>{ StringUtils.displayGender(pet.gender) }</Text>
              <Text style={{ fontSize: 15, fontWeight: 'bold', color: '#1A1D1F' }}>{ StringUtils.displayName(pet) }</Text>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            { this.state.display_edit_buttons ? <Text style={{ color: StyleUtils.get_button_color(), marginRight: 10, fontSize: 14, fontWeight: 'bold' }}>Edit</Text> : null }
            { this.state.display_edit_buttons ? <span className='fas fa-chevron-right' /> : null }
            { is_selected && !this.state.display_edit_buttons ? <span className='fas fa-check-circle' style={{ fontSize: 20, color: '#75D392' }} /> : null }
          </div>
        </div>
      </div>
    }).filter((row) => { return row });

    let no_pets  = pets_rows.length === 0;
    let subtitle = this.state.display_edit_buttons ? "Select a pet to edit" : 'Select a pet to get started';
        subtitle = no_pets ? 'Add your first pet to get started' : subtitle;

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
        <Text style={{ fontSize: 14, marginTop: 5, marginBottom: 15, color: '#1A1D1F', fontWeight: 'bold' }}>{ subtitle }</Text>
        { no_pets || !allow_add || this.state.selected_pet_id ? null : <Button title={ this.state.display_edit_buttons ? 'Done Editing' : 'Edit Pets' } titleStyle={{ fontSize: 13, color: StyleUtils.get_button_text_color() }} style={{ height: 35, width: 120, borderRadius: 30, background: StyleUtils.get_button_color(), backgroundColor: StyleUtils.get_button_color(), boxShadow: 'none' }} onClick={ () => { this.setState({ display_edit_buttons: !this.state.display_edit_buttons, updated_error: '' }) }} /> }
      </div>

      <Error error={this.state.updated_error} style={{ marginBottom: 20, marginTop: 10, alignSelf: 'center' }} />

      { pets_rows }
      { no_pets ? <div style={{ height: 80, width: 120, overflow: 'hidden', alignSelf: 'center' }}>
                    <Player autoplay loop src={ require('../animations/cat-tail-wag.json') } style={{ height: '150px', width: '150px', marginTop: -35, marginLeft: -14 }} />
                  </div>
                : <div style={{ height: 5 }} /> }

      { display_add ? <div className='button-grey'
                             style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 160, justifyContent : 'center', alignSelf: 'center' }}
                             onClick={ () => {
                                this.setState({
                                  name: '',
                                  type: '',
                                  error: '',
                                  updated_error: '',
                                  weight: '',
                                  gender: '',
                                  spayed_neutered: false,
                                  display_pet_inputs: true
                                });
                             }}>
                           <span className='fas fa-plus-circle' style={{ color: '#4c4c4c', marginRight: 5 }} />
                           <Text style={{ fontSize: 14, color: '#4c4c4c', fontWeight: 'bold' }}>Add New Pet</Text>
                         </div>
                      : null }

      <Line hide={true} style={{ marginTop: 10, marginBottom: 15 }}/>
    </div>
  }

  render_add_pet_button = () => {
    if (!this.state.selected_pet_id || this.state.display_edit_buttons) {
      return null;
    }

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Button title='Continue'
              style={{ width: 300, marginTop: 5, height: 55, alignSelf: 'center' }}
              titleStyle={{ fontSize: 16 }}
              loading={this.state.loading_continue}
              onClick={ async () => {
                this.setState({ loading_continue: true, updated_error: '' });

                let pet_id               = this.state.selected_pet_id;
                let referral_code        = getItem('referral_code');
                let care_consultation_id = getItem('care_consultation_id');
                let patient_id           = pet_id;
                let care_consultation_update = {
                  care_consultation_id: care_consultation_id,
                  referral_code: referral_code,
                  patient_id: patient_id
                }

                let update_response = await ConsultationController.editCareConsultation(care_consultation_update);
                let updated_consult = update_response && update_response.success && update_response.data && update_response.data.care_consultation ? update_response.data.care_consultation : {};
                let updated_error   = update_response && update_response.error   ?  update_response.error : '';

                if (updated_consult && updated_consult._id) {
                  setItem('care_consultation', updated_consult);
                  await NavigationUtils.navigate_to_consultation(this.props.navigate);
                }

                this.setState({ loading_continue: false, updated_error: updated_error });
              }}/>
    </div>
  }

  render_screen_loading = (screen_title) => {
    return <Screen shadow={false}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
        <Header title={ screen_title } />
        <div style={{ height: 50 }} />
        <Loading type='bored_cat' title='Loading Pets...' />
      </div>
    </Screen>
  }

  render() {
    let screen_title = 'Pets'
        screen_title = this.state.display_pet_inputs ? this.state.input_section_title : screen_title;

    if (this.state.loading_screen) {
      return this.render_screen_loading(screen_title);
    }

    return (
      <Screen shadow={false}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
          <Header title={ screen_title } />
          { this.render_pet_inputs()     }
          { this.render_pets_list()      }
          { this.render_add_pet_button() }
        </div>
      </Screen>
    );
  }

}
