import React from "react";
import { StyleUtils } from '../utils';
import { Spinner, Text } from '../components';
import { Player } from '@lottiefiles/react-lottie-player';

export default class Button extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    let button_title = this.props.title      || 'Button';
    let title_style  = this.props.titleStyle || {};
    let is_disabled  = this.props.disabled   || false;
    let is_grey      = this.props.grey       || false;
    let bg_color     = StyleUtils.get_button_color();
        bg_color     = this.props.style && this.props.style.backgroundColor ? this.props.style.backgroundColor : bg_color;
        bg_color     = is_grey     ? '#e7e7e7' : bg_color;
        bg_color     = is_disabled ? '#e7e7e7' : bg_color;
    let background   = StyleUtils.get_button_color();
        background   = this.props.style && this.props.style.background ? this.props.style.background : background;
        background   = is_grey     ? '#FCFCFC' : background;
        background   = is_disabled ? '#e7e7e7' : background;
    let shadow       = this.props.style && this.props.style.boxShadow ? this.props.style.boxShadow : '';
        shadow       = is_grey     ? 'none' : shadow;
        shadow       = is_disabled ? 'none' : shadow;

    return (
        <span className={ StyleUtils.get_class('button-default') }
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: bg_color, background: background, boxShadow: shadow, borderWidth: is_grey ? 2 : 0.1, borderColor: is_grey ? '#EFEFEF' : 'transparent', ...this.props.style }}
              onClick={ () => {
                if (this.props.loading || is_disabled) {
                  return;
                }
                if (this.props.onClick) {
                  this.props.onClick();
                }
              }}>
          { this.props.loading ? <Player autoplay loop src={ require('../animations/white-spinner.json') } style={{ height: '20px', width: '20px' }} />
                               : <Text style={{ fontWeight: '800', fontSize: 19, color: is_grey ? '#4c4c4c' : StyleUtils.get_button_text_color(), ...title_style }}>{ button_title }</Text> }
        </span>
    );
  }
}
