import './index.css';
import './css/default.css';
import './css/hills.css';
import './css/teletails.css';
import './css/drmarty.css';
import './css/snugglepuppy.css';
import './css/darwins.css';
import './css/getjoy.css';
import './css/ussa.css';
import './css/wag.css';
import './css/wagmo.css';
import './css/zestypaws.css';
import './css/geniustag.css';
import './css/conciergevet.css';
import './css/fable.css';
import './css/amazon.css';
import './css/pupford.css';
import './css/upc.css';
import './css/embark.css';

import App from './App';
import React    from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals   from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
