import React from "react";
import { config } from '../config/index';
import { Button, Screen, Text, Spinner, Error, Loading } from '../components';
import { IntroSlides, Header, SignIn, CareSelection, NameZipcode, PetSelection, CareHome } from '../containers';
import { NavigationUtils }    from '../utils';
import { getItem, setItem }   from '../Storage';
import { Routes }             from '../navigation';
import { Player }             from '@lottiefiles/react-lottie-player';
import UserController         from '../controllers/userController';
import ConsultationController from '../controllers/consultationController';

export default class HomeScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      partner: {},
      is_signed_in: false,
      loading_screen: false,
      display_section: 'sign_in',
      default_category: '',
      consultation_type: '',
      consultation_category: '',
      loading_care_selection: false,
      category_enabled: true,
      care_selection_error: '',
      available_live_providers: false,
      available_live_video_providers: false,
      full_screen_loading: true,
      hide_video_option: false,
      hide_chat_option: false,
      display_home_screen: false,
      training_credits: 0,
      airtable_access_enabled: false,
      is_home_screen: true,
      articles: {},
      active_chats: [],
      upcoming_video_consultations: []
    };
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  get_connected_partner_code = async (url_params) => {
    let partner_code = config.DEFAULT_PARTNER_CODE;
    let session_code = this.get_session_code(url_params);

    let care_form_session_res = await ConsultationController.getCareFormSessionData(session_code);
    let is_session_expired    = care_form_session_res && care_form_session_res.success === false && care_form_session_res.error && care_form_session_res.error.includes('expired');
    let is_session_invalid    = care_form_session_res && care_form_session_res.success === false && care_form_session_res.error && care_form_session_res.error.includes('Invalid');
    let care_form_session     = care_form_session_res && care_form_session_res.success  && care_form_session_res.data && care_form_session_res.data.care_form_session ? care_form_session_res.data.care_form_session : {};
        partner_code          = care_form_session     && care_form_session.partner      && care_form_session.partner.code ? care_form_session.partner.code : config.DEFAULT_PARTNER_CODE;
    let user_id               = care_form_session     && care_form_session.account_data && care_form_session.account_data.client_hash  ? care_form_session.account_data.client_hash  : '';
    let token                 = care_form_session     && care_form_session.account_data && care_form_session.account_data.session_hash ? care_form_session.account_data.session_hash : '';
    let user                  = care_form_session     && care_form_session.account_data && care_form_session.account_data.user         ? care_form_session.account_data.user         : '';

    this.process_form_session(care_form_session, is_session_expired, is_session_invalid);

    if (user_id && token) {
      setItem('user', user);
      setItem('token', token);
      setItem('user_id', user_id);
    }

    return partner_code;
  }

  get_url_partner_code = async () => {
    let current_url  = window.location.href;
    let split_up     = current_url.split('/');

    let is_connected = split_up.includes('connected')
    if (is_connected) {
      let partner_code = await this.get_connected_partner_code(split_up)
      return partner_code;
    }

    let codes_arr    = split_up  && split_up.length  > 0 ? split_up[split_up.length - 1].split('-') : '';
    let partner_code = codes_arr && codes_arr.length > 0 && codes_arr[0] ? codes_arr[0] : config.DEFAULT_PARTNER_CODE;
        partner_code = partner_code.toLowerCase();

    return partner_code;
  }

  get_referral_code = () => {
    let current_url   = window.location.href;
    let split_up      = current_url.split('/');
    let referral_code = ''

    split_up.forEach((url_strings) => {
      let url_string  = url_strings.toLowerCase();
      let full_url    = current_url ? current_url.toLowerCase() : '';
      if ((full_url.includes('localhost') || full_url.includes('hills')) && url_string.includes('ah')) {
        referral_code = url_strings;
      }
    });

    return referral_code;
  }

  componentDidMount = () => {

    let full_url      = window.location.href
    let url_has_wagmo = full_url.includes('wagmo');
    let url_has_contd = full_url.includes('connected');
    let display_wagmo = url_has_wagmo && !url_has_contd;

    this.setState({ loading_screen: true, display_wagmo: display_wagmo }, async () => {
      let partner_code = await this.get_url_partner_code();
          partner_code = config.PARTNER_CODE ? config.PARTNER_CODE : partner_code;
      let refrl_code   = this.get_referral_code();
      let partner      = await UserController.getPartnerDetails({ code: partner_code });
      let partner_id   = partner && partner._id         ? partner._id         : '';
      let practice_id  = partner && partner.practice_id ? partner.practice_id : '';
      let partner_name = partner && partner.name        ? partner.name        : '';
      let is_home_enbl = partner && partner.enable_home_screen && partner.enable_home_screen === true ? true : false;
      let token        = getItem('token');
      let user_id      = getItem('user_id');
      let is_signed_in = token   && user_id ? true : false;
      let default_cat  = partner && partner.default_category   ? partner.default_category   : '';
      let live_enabled = partner && partner.live_video_enabled ? partner.live_video_enabled : '';
      let train_enbled = partner && partner.training_enabled   ? partner.training_enabled   : '';

      let airtable_enb = partner && partner.airtable_access_enabled ? partner.airtable_access_enabled : '';
      let airtable_val = { training_credits: 0, training_enabled: false, health_enabled: false };
      let hide_chat    = false;
      let hide_video   = false;
      let train_credit = 0;

      let is_connected = this.state.is_connected_care;
      let is_pet_first = partner && partner.name === 'wagmo';

      let display_sect = is_signed_in === true ? 'care_selection' : 'sign_in';
          display_sect = is_pet_first === true ? 'pet_selection'  : display_sect;
          display_sect = partner.slides_enabled === true && !is_signed_in ? 'slides' : display_sect;
          display_sect = partner.slides_enabled === true && is_connected  ? 'slides' : display_sect;

      if (this.determine_collecting_name_zipcode() && is_signed_in && display_sect === 'care_selection'){
          display_sect = 'name_zipcode';
      }

      setItem('partner', partner);
      setItem('partner_id', partner_id);
      setItem('practice_id', practice_id);
      setItem('partner_name', partner_name);
      setItem('referral_code', refrl_code);
      setItem('care_consultation_id', '');
      setItem('is_video_scheduled', false);

      airtable_enb = partner && partner.name === 'embark' ? true : airtable_enb;

      if (is_signed_in && airtable_enb) {
        airtable_val = await this.retieve_airtable_access(partner_name);
        train_enbled = airtable_val && airtable_val.training_enabled === true ? true  : false;
        hide_chat    = airtable_val && airtable_val.health_enabled   === true ? false : true;
        hide_video   = airtable_val && airtable_val.health_enabled   === true ? false : true;
        train_credit = airtable_val && airtable_val.training_credits ? airtable_val.training_credits : 0;
      }

      if (is_signed_in) {
        this.retrieve_online_live_video_providers();
      }

      if (is_signed_in) {
        this.retrieve_online_providers();
        this.retrieve_upcoming_video_appointments();
        this.retrieve_active_chats();
        this.retrieve_articles();
      }

      await this.sleep(2400);

      this.setState({ partner: partner, partner_id: partner_id, is_signed_in: is_signed_in, display_section: display_sect, loading_screen: false, default_category: default_cat, live_video_enabled: live_enabled, is_pet_first: is_pet_first, training_enabled: train_enbled, hide_chat_option: hide_chat, hide_video_option: hide_video, training_credits: train_credit, airtable_access_enabled: airtable_enb, display_home_screen: is_home_enbl });
    });
  }

  render_slides = () => {
    if (this.state.display_section !== 'slides') {
      return null;
    }

    let partner_name = getItem('partner_name');
    let partner      = getItem('partner');

    return <IntroSlides partner_name={ partner_name }
                        partner={ partner }
                        button_action={ () => {
                          let is_signed_in = this.state.is_signed_in;
                          let is_pet_first = this.state.is_pet_first;

                          this.repull_partner_details();

                          let disp_section = is_signed_in &&  is_pet_first ? 'pet_selection'  : 'sign_in';
                              disp_section = is_signed_in && !is_pet_first ? 'care_selection' : disp_section;
                          this.setState({ display_section: disp_section })
                        }} />
  }

  render_sign_in = () => {
    if (this.state.display_section !== 'sign_in') {
      return null;
    }

    return <SignIn locked_email={this.state.locked_email} success_action={ () => { this.proccess_sign_in() }}/>
  }

  render_name_zipcode = () => {
    if (this.state.display_section !== 'name_zipcode') {
      return null;
    }

    return <NameZipcode success_action={ () => { this.proccess_sign_in() }} />
  }

  render_care_selection = () => {
    if (this.state.display_section !== 'care_selection') {
      return null;
    }

    let is_home_screen = this.state.display_home_screen;

    if (is_home_screen && this.state.consultation_type === '') {
      return <CareHome articles={this.state.articles} 
                       navigate={this.props.navigate}   
                       training_credits={this.state.training_credits}
                       active_chats={this.state.active_chats}
                       upcoming_video_consultations={this.state.upcoming_video_consultations}
                       onTypeChange={(consultation_type) => {
                         this.setState({ consultation_type: consultation_type, consultation_category: '' });
                       }}
                       onCategoryChange={(consultation_category) => {
                         this.setState({ consultation_category: consultation_category });
                       }}
                        />
    }

    return <CareSelection
              error={this.state.care_selection_error}
              training_credits={this.state.training_credits}
              default_category={this.state.default_category}
              category_enabled={this.state.category_enabled}
              consultation_type={this.state.consultation_type}
              training_enabled={this.state.training_enabled || this.state.is_training_only || this.state.is_training_enabled}
              hide_chat_option={this.state.hide_chat_option || this.state.is_training_only}
              hide_video_option={ (this.state.partner && this.state.partner.hide_video_option === true) || this.state.hide_video_option || this.state.is_training_only}
              hide_training_option={this.state.hide_training_option}
              live_video_available={this.state.available_live_video_providers && this.state.live_video_enabled}
              display_change_pet={this.state.is_pet_first}
              partner={this.state.partner}
              consultation_category={this.state.consultation_category}
              training_access_url={this.state.partner && this.state.partner.training_access_url ? this.state.partner.training_access_url : ''}
              airtable_access_enabled={this.state.airtable_access_enabled}
              loading_continue_button={this.state.loading_care_selection}
              online_providers_available={this.state.available_live_providers}
              change_pet_action={ () => { this.setState({ display_section: 'pet_selection' }) }}
              onCategoryChange={ (consultation_category) => {
                this.setState({ consultation_category: consultation_category });
              }}
              onTypeChange={ (consultation_type) => {
                this.setState({ consultation_type: consultation_type, consultation_category: '' });
              }}
              continue_action={ async () => {
                this.setState({ loading_care_selection: true, care_selection_error: '' });

                let partner    = getItem('partner');
                let partner_id = getItem('partner_id');
                let is_async   = this.state.consultation_type === 'ASYNC_CHAT';
                let type       = this.state.consultation_type === 'VIDEO'      ? 'VIDEO'      : 'UNASSIGNED';
                    type       = this.state.consultation_type === 'TRAINING'   ? 'VIDEO'      : type;
                    type       = this.state.consultation_type === 'LIVE_VIDEO' ? 'LIVE_VIDEO' : type;
                    type       = this.state.consultation_type === 'LIVE_CHAT'  ? 'CHAT'       : type;
                    type       = this.state.consultation_type === 'ASYNC_CHAT' ? 'CHAT'       : type;
                let category   = this.state.consultation_category;
                let is_training= this.state.consultation_type === 'TRAINING';

                let request_body = {
                  partner_id: partner_id,
                  is_async: is_async,
                  type: type,
                  category: category,
                  is_training: is_training
                }

                if (this.state.selected_patient_id) {
                  request_body['patient_id'] = this.state.selected_patient_id;
                }

                if (this.state.getjoy_id) {
                  request_body['getjoy_id'] = this.state.getjoy_id;
                }

                let consultation_response = await ConsultationController.createCareConsultation(request_body);
                let is_success            = consultation_response && consultation_response.success ? true : false;
                let care_consultation     = is_success && consultation_response && consultation_response.data && consultation_response.data.care_consultation ? consultation_response.data.care_consultation : {};
                let care_error            = consultation_response && consultation_response.error   ? consultation_response.error : '';
                let skip_pets_screen      = partner && partner.name === 'wagmo';

                if (care_consultation && care_consultation._id) {
                  setItem('care_consultation', care_consultation);
                  setItem('care_consultation_id', care_consultation._id);
                  if (skip_pets_screen) {
                    await NavigationUtils.navigate_to_consultation(this.props.navigate);
                  } else {
                    this.props.navigate(Routes.PETS);
                  }
                }

                this.setState({ loading_care_selection: false, care_selection_error: care_error });
              }} />
  }

  render_pet_selection = () => {
    if (this.state.display_section !== 'pet_selection') {
      return null;
    }

    return <PetSelection pet_selection_action={ (pet_selected_data) => { this.process_pet_selected(pet_selected_data) }} />
  }

  render_screen_loading = () => {
    return <Screen>
      <div style={{ height: 100 }} />
      <Loading type='walking_dog' title='Gathering Info...' />
    </Screen>
  }

  render_session_expired = () => {
    let message = this.state.is_session_invalid ? 'Your Session Link Is Invalid' : 'Your Session Has Expired';
    return <Screen>
      <div style={{ height: 100 }} />
      <div className='flex-container-column' style={{ alignItems: 'center' }}>
        <Player autoplay loop src={ require('../animations/session-ended.json') } style={{ height: '50px', width: '50px', marginTop: -15, marginBottom: -15  }} />
        <div style={{ marginTop: 15, fontSize: 15, fontWeight: 'bold', color: '#4c4c4c' }}>{ message }</div>
      </div>
    </Screen>
  }

  render_wagmo_logo = () => {
    return <Screen>
      <div style={{ height: 100 }} />
      <div className='flex-container-column' style={{ alignItems: 'center' }}>
        <img style={{ height: 40, width: 'auto'}} src={'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/image%20(36).png'} alt="Icon Text" />
      </div>
    </Screen>
  }

  render() {

    if (this.state.display_wagmo) {
      return this.render_wagmo_logo();
    }

    if (this.state.loading_screen) {
      return this.render_screen_loading();
    }

    if (this.state.is_session_expired || this.state.is_session_invalid) {
      return this.render_session_expired();
    }

    let enable_bg_image = this.state.display_home_screen === true ? false : true;

    return (
      <Screen bg_image={enable_bg_image} intro_slides={this.state.display_section === 'slides'}>
        { this.render_slides()  }
        { this.render_sign_in() }
        { this.render_name_zipcode()   }
        { this.render_care_selection() }
        { this.render_pet_selection()  }
      </Screen>
    );
  }

  process_pet_selected = (pet_selected_data) =>{
    let patient_id    = pet_selected_data.patient_id;
    let features_key  = pet_selected_data.pet_features_key || '';
    let features_dict = this.state.pet_features_dict || {};
    let features_arr  = features_dict[features_key] || [];

    let is_chat       = features_arr.includes('CHAT');
    let is_video      = features_arr.includes('VIDEO');
    let is_training   = features_arr.includes('TRAINING');

    this.setState({ hide_chat_option: !is_chat, hide_video_option: !is_video, hide_training_option: !is_training, display_section: 'care_selection', selected_patient_id: patient_id });
  }

  proccess_sign_in = () => {
    let token    = getItem('token');
    let user_id  = getItem('user_id');

    if (token && user_id) {
      let get_info  = this.determine_collecting_name_zipcode();
      let d_section = get_info ? 'name_zipcode' : 'care_selection';
      this.retrieve_online_providers();
      this.retrieve_online_live_video_providers();
      this.retrieve_upcoming_video_appointments();
      this.retrieve_active_chats();
      this.retrieve_articles();
      this.process_airtable_access();
      this.setState({ user_id: user_id, token: token, display_section: d_section });
    } else {
      this.setState({ user_id: '', token: '', display_section: 'sign_in' });
    }
  }

  retrieve_articles = async () => {
    let partner = getItem('partner');
    let partner_name = partner && partner.name ? partner.name : '';
    let airtable_access_res = await UserController.getPartnerArticles(partner_name);
    let articles            = airtable_access_res && airtable_access_res.data && airtable_access_res.data.articles ? airtable_access_res.data.articles : {};
    this.setState({ articles: articles });
    }

  retrieve_upcoming_video_appointments = async () => {
    let partner_id = getItem('partner_id');
    let user = getItem('user');
    let upcoming_video_consultations_response = await ConsultationController.getUpcomingVideoConsultations(partner_id);
    let upcoming_video_consultations = upcoming_video_consultations_response && upcoming_video_consultations_response.data && upcoming_video_consultations_response.data.care_consultations ? upcoming_video_consultations_response.data.care_consultations : [];
    console.log('upcoming_video_consultations', upcoming_video_consultations_response);
    this.setState({ upcoming_video_consultations: upcoming_video_consultations });
  }

  retrieve_active_chats = async () => {
    let partner_id = getItem('partner_id');
    let active_chats_response = await ConsultationController.getClientChatConsultations(partner_id);
    let active_chats = active_chats_response && active_chats_response.data && active_chats_response.data.care_consultations ? active_chats_response.data.care_consultations : [];
    this.setState({ active_chats: active_chats });
  }

  retrieve_online_providers = async () => {
    let providers_available = false;
    let practice_id         = getItem('practice_id');
    let partner_id          = getItem('partner_id');
    let available_response  = await ConsultationController.getOnlineChatProviders({ practice_id, partner_id });
        providers_available = available_response && available_response.available_provider_count && available_response.available_provider_count > 0 ? true : false;

    this.setState({ available_live_providers : providers_available });
  }

  retrieve_online_live_video_providers = async () => {
    let practice_id              = getItem('practice_id');
    let partner_id               = getItem('partner_id');
    let request_data             = { practice_id: practice_id, partner_id: partner_id }
    let online_live_providers    = await ConsultationController.getOnlineLiveVideoProviders(request_data);
    let available_live_providers = online_live_providers.data && online_live_providers.data.avialable_provider_count ? online_live_providers.data.avialable_provider_count : 0;
    let enable_live_button       = available_live_providers > 0 ? true : false;
    this.setState({ available_live_video_providers: enable_live_button });
  }

  retieve_airtable_access = async (partner_name) => {
    let airtable_access_res = await UserController.getAirtableAccess({ partner_name: partner_name });
    let is_success          = airtable_access_res && airtable_access_res.success ? true : false;

    if (is_success) {
      let airtable_training_credits = airtable_access_res && airtable_access_res.data && airtable_access_res.data.training_credits ? airtable_access_res.data.training_credits : 0;
      let airtable_training_enabled = airtable_access_res && airtable_access_res.data && airtable_access_res.data.training_enabled ? airtable_access_res.data.training_enabled : false;
      let airtable_health_enabled   = airtable_access_res && airtable_access_res.data && airtable_access_res.data.health_enabled   ? airtable_access_res.data.health_enabled   : false;
      return { training_credits: airtable_training_credits, training_enabled: airtable_training_enabled, health_enabled: airtable_health_enabled };
    }

    return { training_credits: 0, training_enabled: false, health_enabled: false };
  }

  process_airtable_access = async () => {
    let partner      = getItem('partner');
    let partner_name = partner && partner.name ? partner.name : '';

    if (this.state.airtable_access_enabled) {
      let airtable_val = await this.retieve_airtable_access(partner_name);
      let train_enbled = airtable_val && airtable_val.training_enabled === true ? true  : false;
      let hide_chat    = airtable_val && airtable_val.health_enabled   === true ? false : true;
      let hide_video   = airtable_val && airtable_val.health_enabled   === true ? false : true;
      let train_credit = airtable_val && airtable_val.training_credits ? airtable_val.training_credits : 0;
      this.setState({ training_enabled: train_enbled, hide_chat_option: hide_chat, hide_video_option: hide_video, training_credits: train_credit });
    }
  }

  determine_collecting_name_zipcode = () => {
    let user = getItem('user');
    let has_default_name = user && user.first_name === 'Pet' && user.last_name === 'Owner' ? true : false;
    return has_default_name;
  }

  get_session_code = (params_array) => {
    let params       = params_array || [];
    let session_code = '';
    params.forEach((param, i) => {
      if (param.includes('cc-')) {
        session_code = param.substring(3, param.length)
      }
    });
    return session_code;
  }

  process_form_session = (care_form_session, is_session_expired, is_session_invalid) => {
    let pet_features        = this.get_pet_features(care_form_session);
    let is_training_enabled = care_form_session && care_form_session.account_data && care_form_session.account_data.is_training_enabled ? true : false;
    let is_training_only    = care_form_session && care_form_session.account_data && care_form_session.account_data.is_training_only    ? true : false;
    let locked_email        = care_form_session && care_form_session.partner      && care_form_session.partner.name      && care_form_session.partner.name === 'getjoy' && care_form_session.account_data && care_form_session.account_data.user && care_form_session.account_data.user.email ? care_form_session.account_data.user.email : '';
    let getjoy_id           = care_form_session && care_form_session.account_data && care_form_session.account_data.user && care_form_session.account_data.user.getjoy_id ? care_form_session.account_data.user.getjoy_id : '';

    this.setState({ pet_features_dict: pet_features, is_training_enabled: is_training_enabled, is_training_only: is_training_only, is_connected_care: true, is_session_expired: is_session_expired, locked_email: locked_email, is_session_invalid: is_session_invalid, getjoy_id: getjoy_id });
  }

  get_pet_features = (care_form_session) => {
    let pets_array   = care_form_session && care_form_session.account_data && care_form_session.account_data.pets ? care_form_session.account_data.pets : [];
    let pet_features = {};

    pets_array.forEach((pet, i) => {
      let pet_name = pet.name.toLowerCase();
      let pet_type = pet.type.toLowerCase();
      let pet_key  = pet_name + '_' + pet_type;
      pet_features[pet_key]  = pet.features || [];
    });

    return pet_features;
  }

  repull_partner_details = async () => {
    let stored_name  = getItem('partner_name');

    if (!stored_name) {
      let partner_code = await this.get_url_partner_code();
          partner_code = config.PARTNER_CODE ? config.PARTNER_CODE : partner_code;
      let partner      = await UserController.getPartnerDetails({ code: partner_code });
      let partner_id   = partner && partner._id         ? partner._id         : '';
      let partner_name = partner && partner.name        ? partner.name        : '';
      setItem('partner', partner);
      setItem('partner_id', partner_id);
      setItem('partner_name', partner_name);
    }
  }

}
