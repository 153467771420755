import React from "react";
import { getItem, setItem } from '../Storage';
import { Button, Text, Input, CodeInput, Error, Line } from '../components';
import { StyleUtils } from '../utils';
import { Header } from '../containers';
import AuthController from '../controllers/authController';

export default class SignIn extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      code: '',
      error: '',
      display_code_input: false,
      loading_email: false,
      loading_verify: false,
      no_airtable_access: false
    };
  }

  componentDidMount() {

  }

  verify_code_action = async (auto_code) => {
    this.setState({ loading_verify: true, error: '' });

    let partner_name  = getItem('partner_name');
    let email_address = this.state.email;

    if (this.props.locked_email) {
      email_address = this.props.locked_email;
    }

    let code          = auto_code ? auto_code : this.state.code;
    let code_response = await AuthController.signInSignOut_Verify({ email: email_address, host_name: partner_name, code: code });
    let code_success  = code_response && code_response.success ? true : false;
    let token         = code_response && code_response.token   ? code_response.token   : '';
    let user_id       = code_response && code_response.user_id ? code_response.user_id : '';
    let error_message = code_response && code_response.error   ? code_response.error   : '';

    if (code_success && this.props.success_action) {
      setItem('token', token);
      setItem('user_id', user_id);

      let user_details = await AuthController.getLoggedInUser();

      this.setState({ loading_verify: false, error: '' })
      this.props.success_action();
    } else {
      this.setState({ loading_verify: false, error: error_message })
    }
  }

  resend_code_action = async () => {
    this.setState({
      email: '',
      code: '',
      error: '',
      display_code_input: false,
      loading_email: false,
      loading_verify: false
    })
  }

  render_code_input = () => {
    if (!this.state.display_code_input) {
      return null;
    }

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Header title='Enter Code' subtitle="We’ve emailed you a verification code" />
      <CodeInput value={this.state.code}
                 hide_label={true}
                 style={{ borderRadius: 12, height: 55 }}
                 placeholder='0000'
                 onChange={ (text_input) => {
                   if (text_input.length === 4) {
                     this.verify_code_action(text_input);
                   }
                   this.setState({ code: text_input })
                 }}/>
      <Button title={'Verify Code'}
              style={{ height: 55, marginTop: 25, marginRight: 10, marginLeft: 10, width: 300, alignSelf: 'center', boxShadow: 'none' }}
              titleStyle={{ fontSize: 16 }}
              loading={this.state.loading_verify}
              onClick={ () => {
                this.verify_code_action();
              }}/>
      <Button title={'Resend Code'}
              grey={true}
              style={{ marginTop: 10, alignSelf: 'center', height: 35, paddingLeft: 25, paddingRight: 25 }}
              titleStyle={{ fontSize: 12 }}
              onClick={ () => {
                this.resend_code_action();
              }}/>

      <Line style={{ marginTop: 20, marginBottom: 20 }} />
      <div className='flex-container-column' style={{ alignItems: 'center' }}>
        <Text style={{ fontSize: 14, paddingLeft: 4, fontWeight: 'bold', color: '#6F767E' }}>{ 'Didn’t get a code? Please check your spam folder.' }</Text>
      </div>
    </div>
  }

  render_email_input = () => {
    if (this.state.display_code_input) {
      return null;
    }

    let email     = this.state.email;
    let disabled  = false;

    if (this.props.locked_email) {
      email    = this.props.locked_email;
      disabled = true;
    }

    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Header title='Email Address' subtitle='Please enter your email address' />
      <Input type='email'
             value={email}
             disabled={disabled}
             style={{ width: '100%', borderRadius: 12, height: 55, paddingLeft: 20 }}
             container_style={{  }}
             placeholder={'Your Email'}
             onChange={ (text_input) => {
               this.setState({ email: text_input })
             }} />
      <Button title={'Continue'}
              style={{ height: 55, marginTop: 25, marginRight: 10, marginLeft: 10, width: 300, alignSelf: 'center' }}
              titleStyle={{ fontSize: 16 }}
              loading={this.state.loading_email}
              onClick={ async () => {
                this.setState({ loading_email: true });
                let partner_name      = getItem('partner_name');
                let email_address     = email;
                let sign_in_response  = await AuthController.signInSignOut({ email: email_address, host_name: partner_name, short_code: true });
                let code_sent_success = sign_in_response && sign_in_response.success ? true : false;
                let error_message     = sign_in_response && sign_in_response.error   ? sign_in_response.error : '';
                let no_airtable_access= sign_in_response && sign_in_response.no_airtable_access && sign_in_response.no_airtable_access === true ? true : false;
                this.setState({ display_code_input: code_sent_success, loading_email: false, error: error_message, no_airtable_access: no_airtable_access });
              }}/>
    </div>
  }

  /*
  return <div className='flex-container-column' style={{ marginTop: 20, textAlign: 'center', fontWeight: 13, color: '#4c4c4c' }}>
    <span>Looks like you are out of credits. <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginRight: 5 }} target="_blank" href={ training_access_url }>Add credits</a></span>
    <span>or contact <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginRight: 5 }} target="_blank" href="mailto:support@teletails.com">support@teletails.com</a></span>
  </div>
  */

  render_error_section = () => {
    let partner               = getItem('partner');
    let is_no_airtable_access = this.state.no_airtable_access;
    let is_training_access    = partner && partner.training_enabled && partner.training_enabled === true ? true : false;
    let is_health_only_access = !is_training_access;
    let training_access_url   = partner && partner.training_access_url ? partner.training_access_url : '';
    let health_access_url     = partner && partner.health_access_url   ? partner.health_access_url   : '';

    if (is_no_airtable_access && is_training_access) {
      return <div className='flex-container-column' style={{ marginTop: 20, textAlign: 'center', fontWeight: 13, color: '#4c4c4c', fontWeight: 'bold' }}>
        <span>For access, click <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginRight: 5 }} target="_blank" href={ training_access_url }>here</a>or contact <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginRight: 5 }} target="_blank" href="mailto:support@teletails.com">support@teletails.com</a></span>
      </div>
    }

    if (is_no_airtable_access && is_health_only_access) {
      return <div className='flex-container-column' style={{ marginTop: 20, textAlign: 'center', fontWeight: 13, color: '#4c4c4c', fontWeight: 'bold' }}>
        <span>For access, click <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginRight: 5 }} target="_blank" href={ health_access_url }>here</a>or contact <a className="tos-link" style={{ color: StyleUtils.get_primary_color(), marginRight: 5 }} target="_blank" href="mailto:support@teletails.com">support@teletails.com</a></span>
      </div>
    }

    return <div>
      <Error error={this.state.error} style={{ marginTop: 20 }} />
    </div>
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center' }}>
        { this.render_email_input()   }
        { this.render_code_input()    }
        { this.render_error_section() }
      </div>
    );
  }
}
