import React from "react";
import { Header } from '../containers';
import { Routes } from '../navigation';
import { StringUtils, DateUtils, StyleUtils } from '../utils';
import { getItem, setItem } from '../Storage';
import { Button, Text, Input, CheckBox, Error, Line } from '../components';
import '../css/default.css';

import care_home_360 from '../images/360-cta-bg.png';
import veterinary    from '../images/veterinary.png';
import video_camera  from '../images/video-camera.png';
import chat_bubble   from '../images/chat-bubble.png';
import embark_logo   from '../images/embark-logo.svg';

export default class CareSelection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = '#FAFBFF';
    }

    componentDidUpdate = () => {

    }

    render_consult_button = (type) => {
        let button_text = type === 'chat' ? 'Live Chat' : 'Video';
        let is_video    = type === 'video';
        let is_chat     = type === 'chat';
        return <div className="flex-container-row universal-button" 
                    style={{ alignItems: 'center', height: 80, backgroundColor: 'white', width: 160, borderRadius: 15, padding: 15, boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.05)' }}
                    onClick={ () => { 
                        let consultation_type = is_video ? 'VIDEO' : 'LIVE_CHAT';
                        this.props.onTypeChange(consultation_type);
                    }}>
            <div className='flex-container-column' style={{ height: 40, width: 40, backgroundColor: '#f5f5f5', borderRadius: 20, alignItems: 'center', justifyContent: 'center' }}>
                { is_chat ? <img style={{ height: 20, width: 20 }} src={ veterinary } /> : <img style={{ height: 20, width: 20 }} src={ video_camera } /> }
            </div>
            <div className='flex-container-column' style={{ marginLeft: 15 }}>
                <Text style={{ fontSize: 16, fontWeight: 'bold', marginBottom: -3 }}>{ button_text }</Text>
                <Text style={{ fontSize: 14, fontWeight: 'bold', color: 'grey' }}>{ 'Health' }</Text>
            </div>
        </div>
    }

    render_top_section = () => {
        let user         = getItem('user');
        let partner      = getItem('partner');
        let first_name   = user && user.first_name ? StringUtils.sentenceCase(user.first_name) : '';
        let welcome_text = 'Welcome, ' + first_name + '!';
        let top_bg_color = StyleUtils.get_header_color();
        let is_embark    = partner && partner.name && partner.name.toLowerCase() === 'embark' ? true : false;
        let logo_url     = partner && partner.logo_url ? partner.logo_url : '';
            logo_url     = is_embark ? embark_logo : logo_url;

        return <div className="flex-container-column" style={{  width: '100%', height: 270 }}> 
            <div className='flex-container-column' style={{ backgroundColor: top_bg_color, width: '100%', height: 210, padding: 20 }}>
                <div className='flex-container-row' style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
                    <img style={{ height: 'auto', width: 130 }} src={logo_url} />
                </div>
                <Text style={{ fontSize: 18, color: 'black', fontWeight: 'bold', marginBottom: 5 }}> { welcome_text }</Text>
                <Text style={{ fontSize: 22, color: 'black', fontWeight: 'bold' }}> { 'Book a health visit'}</Text>
                <Text style={{ fontSize: 16, color: 'black' }}> {'You have 24/7 access to our pet pros.'}</Text>
            </div>
            <div className='flex-container-row' style={{ position: 'absolute', alignSelf: 'center', top: 180 }}>
                { this.render_consult_button('video') }
                <div style={{ width: 20 }} />
                { this.render_consult_button('chat')}
            </div>
        </div>
    }

    render_training_cta = () => {
        let training_credits  = this.props.training_credits || 0;
        let credits_remaining = training_credits + ' sessions remaining';

        if (training_credits < 1) {
            return null;
        }

        return <div className='flex-container-row' style={{ width: '100%', justifyContent: 'center', marginBottom: 25 }}>
            <div className="flex-container-column" style={{ flex: 1, height: 210, maxWidth: 380, borderRadius: 30, backgroundImage: `url(${care_home_360})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="flex-container-column" style={{ flex: 1, padding: 20, paddingLeft: 35, paddingTop: 45 }}>
                    <Text style={{ color: 'black', fontSize: 18, fontWeight: 'bold' }}>Meet with a professional</Text>
                    <Text style={{ color: 'black', fontSize: 18, fontWeight: 'bold' }}>dog trainer</Text>
                    <div className="flex-container-row" style={{ marginTop: 10, alignItems: 'center' }}>
                        <Text style={{ color: 'black', fontSize: 15, fontWeight: 'bold' }}>{ credits_remaining }</Text>
                    </div>
                    <div className='flex-container-column universal-button' 
                         style={{ marginTop: 10, backgroundColor: 'white', borderRadius: 13, alignSelf: 'flex-start', height: 40, paddingLeft: 30, paddingRight: 30, alignItems: 'center', justifyContent: 'center' }}
                         onClick={ () => { 
                             let consultation_type = 'TRAINING';
                             this.props.onTypeChange(consultation_type);
                         }}>
                        <Text style={{ fontSize: 14, fontWeight: 'bold' }}>Book your session</Text>
                    </div>
                </div>
            </div>
        </div>
    }

    render_360_cta = () => {
        return <div className='flex-container-row' style={{ alignItems: 'center', justifyContent: 'center', padding: 20, paddingRight: 20, paddingLeft: 20, paddingTop: 8, marginBottom: 0 }}>
            <div className='flex-container-row universal-button' 
                style={{ height: 80, flex: 1, backgroundColor: 'white', alignItems: 'center', paddingLeft: 20, borderRadius: 12, boxShadow: '0px 0px 20px 3px rgba(0, 0, 0, 0.03)', maxWidth: 340 }}
                onClick={ () => { 
                    let consultation_type = 'VIDEO';
                    this.props.onTypeChange(consultation_type);
                    this.props.onCategoryChange('wellness');
                }}>
                <div className='flex-container-column' style={{ height: 50, width: 50, backgroundColor: '#f5f5f5', borderRadius: 25, alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ height: 24, width: 24 }} src={video_camera} />
                </div>
                <div className='flex-container-column' style={{ marginLeft: 15 }}>
                    <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{ 'Wellness Check-up' }</Text>
                    <Text style={{ fontSize: 14, color: 'grey' }}>{ 'Schedule a review' }</Text>
                </div>
            </div>
        </div>
    }

    render_article_box = (article) => {
        if (!article.title) { return null }

        let title         = article.title;
        let thumbnail_url = article.thumbnail_url;
        let url           = article.url;

        return <div className="flex-container-column universal-button" 
                    onClick={ () => { window.open(url, '_blank') }}
                    style={{ backgroundColor: 'white', flex: 1, borderRadius: 12, overflow: 'hidden', boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.07)' }}>
            <img style={{ height: 120, objectFit: 'cover' }} src={thumbnail_url} />
            <div style={{ padding: 15 }}>
                <Text style={{ fontSize: 14, fontWeight: 'bold', color: '#4c4c4c' }}>{title}</Text>
            </div>
        </div>
    }

    render_article_section = (article_section) => {
        let section_title = article_section.title;
        let article_1 = article_section && article_section.articles && article_section.articles[0] ? article_section.articles[0] : {};
        let article_2 = article_section && article_section.articles && article_section.articles[1] ? article_section.articles[1] : {};
        return <div className="flex-container-column" style={{ padding: 20, paddingTop: 0 }}>
            <Text style={{ fontSize: 18, fontWeight: 'bold' }}>{ section_title }</Text>
            <div className='flex-container-row' style={{ marginTop: 15 }}>
                { this.render_article_box(article_1) }
                <div style={{ width: 20 }} />
                { this.render_article_box(article_2) }
            </div>
        </div>
    }

    render_article_sections = () => {

        let articles       = this.props.articles;
        let section_titles = Object.keys(articles);
        let article_sections = section_titles.map((section_title) => {
            return {
                title: section_title,
                articles: articles[section_title]
            }
        });

        if (article_sections.length === 0) { return null }

        let article_rows = article_sections.map((article_section) => {
            return this.render_article_section(article_section);
        })

        return <div className='flex-container-column'>    
            { article_rows }
        </div>
    }

    render_chat_box = (chat) => {
        let category_str = chat && chat.category ? StringUtils.keyToDisplayString(chat.category) : 'Health';
        let pet_name     = chat && chat.category ? StringUtils.displayName(chat.patient) : '';
        let title        = pet_name + ': ' + category_str;
        let preview_text = chat && chat.last_message && chat.last_message.content && chat.last_message.content.text ? chat.last_message.content.text : 'Provider Chat';
            preview_text = preview_text.length > 50 ? preview_text.substring(0, 47) + '...' : preview_text;
        let logged_in_user_id = getItem('user_id');
        let last_msg_sndr_id  = chat && chat.last_message && chat.last_message.from ? chat.last_message.from : '';
        let display_red_dot   = last_msg_sndr_id && last_msg_sndr_id !== logged_in_user_id;
        return <div className='flex-container-row universal-button' 
                    style={{ backgroundColor: 'white', borderRadius: 12, padding: 15, marginBottom: 5 }}
                    onClick={ () => { 
                        let partner = getItem('partner');
                        let partner_code = partner && partner.code ? partner.code : '';
                        let consult_code = chat    && chat.code    ? chat.code    : '';
                        this.props.navigate(Routes.CHAT + '/' + partner_code + '-' + consult_code);
                    }}>
            <div className='flex-container-row' style={{ height: 50, width: 50, backgroundColor: '#f5f5f5', borderRadius: 25, alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ height: 20, width: 20 }} src={ chat_bubble } />
            </div>
            <div className='flex-container-column' style={{ marginLeft: 15, justifyContent: 'center', flex: 1 }}>
                <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{ title }</Text>
                <Text style={{ fontSize: 14, color: 'grey' }}>{ preview_text }</Text>
            </div>
            { display_red_dot && <div style={{ width: 14, height: 14, borderRadius: 7, backgroundColor: 'red', alignSelf: 'center', animation: 'glowingEffect 1.5s infinite alternate' }} /> }  
        </div>
    }   

    render_active_chats = () => {
        let active_chats   = this.props.active_chats || [];
        let filtered_chats = active_chats.filter((chat) => { return chat.status === 'ACTIVE' || chat.status === 'IN_PROGRESS' });

        if (filtered_chats.length === 0) { return null }

        let chat_rows = filtered_chats.map((chat) => {
            return this.render_chat_box(chat);
        });

        return <div className='flex-container-column' style={{ padding: 20, paddingTop: 0 }}>
            <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10 }}>{ 'Active Chats' }</Text>
            { chat_rows }
        </div>
    }

    render_upcoming_video_appointment_box = (care_consultation) => {
        let category_str = care_consultation && care_consultation.category ? StringUtils.keyToDisplayString(care_consultation.category) : 'Health';
        let pet_name     = care_consultation && care_consultation.category ? StringUtils.displayName(care_consultation.patient) : '';
        let title        = pet_name + ': ' + category_str;
        let is_training  = care_consultation && care_consultation.is_training ? true : false;
        
        let time_string    = '';
        let date_string    = '';
        let apt_time       = care_consultation.appointment && care_consultation.appointment.time ? care_consultation.appointment.time : '';
        let sub_tlt_prefix = is_training ? 'Video Training Session' : 'Video Appointment';

        if (apt_time) {
            time_string = DateUtils.getHoursString(apt_time) + ':' + DateUtils.getMinutesString(apt_time) + ' ' + DateUtils.getAMPMString(apt_time);
            date_string = DateUtils.getShortWeekday(apt_time) + ' ' + DateUtils.getShortMonth(apt_time) + ' ' + DateUtils.getDateNumber(apt_time);
        }

        let subtitle = sub_tlt_prefix + ' - ' + date_string + ' - ' + time_string ;
        return <div className='flex-container-row universal-button'
            style={{ backgroundColor: 'white', borderRadius: 12, padding: 15, marginBottom: 5 }}
            onClick={() => {
                let partner      = getItem('partner');
                let partner_code = partner && partner.code ? partner.code : '';
                let consult_code = care_consultation && care_consultation.code ? care_consultation.code : '';
                this.props.navigate(Routes.APPOINTMENT + '/' + partner_code + '-' + consult_code);
            }}>
            <div className='flex-container-row' style={{ height: 50, width: 50, backgroundColor: '#f5f5f5', borderRadius: 25, alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ height: 20, width: 20 }} src={video_camera} />
            </div>
            <div className='flex-container-column' style={{ marginLeft: 15, justifyContent: 'center', flex: 1 }}>
                <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{title}</Text>
                <Text style={{ fontSize: 14, color: 'grey' }}>{subtitle}</Text>
            </div>
        </div>
    }

    render_upcoming_video_appointments = () => {
        let upcoming_video_consultations = this.props.upcoming_video_consultations || [];
        if (upcoming_video_consultations.length === 0) { return null }

        let upcoming_video_consultations_rows = upcoming_video_consultations.map((consultation) => {
            return this.render_upcoming_video_appointment_box(consultation);
        });

        return <div className='flex-container-column' style={{ padding: 20, paddingTop: 0 }}>
            <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10 }}>{ 'Upcoming Video Appointments' }</Text>
            { upcoming_video_consultations_rows }
        </div>
    }

    render() {
        return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignSelf: 'center', backgroundColor: '#FAFBFF', boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.05)' }}>
            <style>{`@keyframes glowingEffect {0% {background-color: red; box-shadow: 0 0 5px red;} 100% {background-color: #ff8c00; box-shadow: 0 0 20px #ff8c00;}}`}</style>
            
            { this.render_top_section()  }
            {this.render_360_cta()}
            
            { this.render_active_chats() }
            { this.render_upcoming_video_appointments() }

            
            {this.render_training_cta() }
            { this.render_article_sections() }
        </div>
    }
}
