import React from "react";
import '../css/default.css';
import { StyleUtils }    from '../utils';
import { Text, Spinner } from '../components';
import { Player }       from '@lottiefiles/react-lottie-player';
import { getItem }      from '../Storage';

export default class Loading extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_embark: false
    }
  }

  componentDidMount = () => {
    let partner = getItem('partner');
    let is_embark = partner && partner.name && partner.name === 'embark' ? true : false;
    this.setState({ is_embark: is_embark });
  }

  componentDidUpdate = () => {

  }

  render_hugs_animation = () => {
    let container_style = this.props.style;
    let title           = this.props.title;
    let color           = StyleUtils.get_primary_color();
    let text_color      = this.state.is_embark ? 'black' : 'white';
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...container_style }}>
      <Player autoplay loop src={ require('../animations/pet-hugs.json') } style={{ height: '150px', width: '150px' }} />
      <div className='animate-pulse' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 150, justifyContent: 'center', height: 30, borderRadius: 8, backgroundColor: color }}>
        <Spinner color='white' style={{ height: 14, width: 14 }}/>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 5, color: text_color }}>{ title }</Text>
      </div>
    </div>
  }

  render_dog_walking_animation = () => {
    let container_style = this.props.style;
    let title           = this.props.title;
    let color           = StyleUtils.get_primary_color();
    let text_color      = this.state.is_embark ? 'black' : 'white';
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...container_style }}>
      <Player autoplay loop src={ require('../animations/dog-walking.json') } style={{ height: '150px', width: '150px', marginBottom: -15 }} />
      <div className='animate-pulse' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 150, justifyContent: 'center', height: 30, borderRadius: 8, backgroundColor: color }}>
        <Spinner color='white' style={{ height: 14, width: 14 }}/>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 5, color: text_color }}>{ title }</Text>
      </div>
    </div>
  }

  render_bored_cat_animation = () => {
    let container_style = this.props.style;
    let title           = this.props.title;
    let color           = StyleUtils.get_primary_color();
    let text_color      = this.state.is_embark ? 'black' : 'white';
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...container_style }}>
      <Player autoplay loop src={ require('../animations/cat-bored.json') } style={{ height: '190px', width: '190px', marginBottom: -45 }} />
      <div className='animate-pulse' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 30, borderRadius: 8, backgroundColor: color, paddingRight: 12, paddingLeft: 10 }}>
        <Spinner color='white' style={{ height: 14, width: 14 }}/>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 6, color: text_color }}>{ title }</Text>
      </div>
    </div>
  }

  render_hidden_cat_animation = () => {
    let container_style = this.props.style;
    let title           = this.props.title;
    let color           = StyleUtils.get_primary_color();
    let text_color      = this.state.is_embark ? 'black' : 'white'; 
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...container_style }}>
      <Player autoplay loop src={ require('../animations/cat-hidden.json') } style={{ height: '150px', width: '150px' }} />
      <div className='animate-pulse' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 140, justifyContent: 'center', height: 30, borderRadius: 8, backgroundColor: color }}>
        <Spinner color='white' style={{ height: 14, width: 14 }}/>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 6, color: text_color }}>{ title }</Text>
      </div>
    </div>
  }

  render_posts_animation = () => {
    let container_style = this.props.style;
    let title           = this.props.title;
    let color           = StyleUtils.get_primary_color();
    let text_color      = this.state.is_embark ? 'black' : 'white';
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...container_style }}>
      <Player autoplay loop src={ require('../animations/posts-loading.json') } style={{ height: '190px', width: '190px', marginBottom: -50 }} />
      <div className='animate-pulse' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 30, borderRadius: 8, backgroundColor: color, paddingRight: 12, paddingLeft: 10 }}>
        <Spinner color='white' style={{ height: 14, width: 14 }}/>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 6, color: text_color }}>{ title }</Text>
      </div>
    </div>
  }

  render_calendar_animation = () => {
    let container_style = this.props.style;
    let title           = this.props.title;
    let color           = StyleUtils.get_primary_color();
    let text_color      = this.state.is_embark ? 'black' : 'white';
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...container_style }}>
      <Player autoplay loop src={ require('../animations/calendar.json') } style={{ height: 100, width: 100, marginBottom: -10 }} />
      <div className='animate-pulse' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 30, borderRadius: 8, backgroundColor: color, paddingRight: 12, paddingLeft: 10 }}>
        <Spinner color='white' style={{ height: 14, width: 14 }}/>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 6, color: text_color }}>{ title }</Text>
      </div>
    </div>
  }

  render_chat_animation = () => {
    let container_style = this.props.style;
    let title           = this.props.title;
    let color           = StyleUtils.get_primary_color();
    let text_color      = this.state.is_embark ? 'black' : 'white';
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...container_style }}>
      <Player autoplay loop src={ require('../animations/chat-loading.json') } style={{ height: 180, width: 180, marginBottom: -40 }} />
      <div className='animate-pulse' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 30, borderRadius: 8, backgroundColor: color, paddingRight: 12, paddingLeft: 10 }}>
        <Spinner color='white' style={{ height: 14, width: 14 }}/>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 6, color: text_color }}>{ title }</Text>
      </div>
    </div>
  }

  render_loading_animation = () => {
    let type = this.props.type || '';

    switch (type) {
      case 'hugs':
        return this.render_hugs_animation();
        break;
      case 'walking_dog':
        return this.render_dog_walking_animation();
        break;
      case 'bored_cat':
        return this.render_bored_cat_animation();
        break;
      case 'hiding_cat':
        return this.render_hidden_cat_animation();
        break;
      case 'posts':
        return this.render_posts_animation();
        break;
      case 'calendar':
        return this.render_calendar_animation();
        break;
      case 'chat':
        return this.render_chat_animation();
        break;
      default:

    }
  }

  render() {
    return this.render_loading_animation();
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
